import { gql } from "@apollo/client";

export const GET_PAYMENT_METHODS = gql`
  query GetPaymentMethods($th_id: uuid!) {
    acc_payment_methods(
      where: { thp_id: { _eq: $th_id } }
      order_by: { brd_id: asc }
    ) {
      account_no
      name
      account_title
      stripe_pub
      stripe_secret
      default
      swift_code
      webhook_secret
      type
      created_at
      updated_at
      id
      thp_id
      brd_id
      card_terminal_type
      brd_list {
        name
      }
    }
  }
`;
