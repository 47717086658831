const OverallStats = ({ sumOfLeaderBoard }: any) => {
  return (
    <div className="grid grid-cols-6 gap-y-10 items-center justify-center">
      <div className="border-l border-l-[5px] border-green-600 h-14 flex items-center">
        <div className="pl-4 flex flex-col w-[280px] items-start justify-center">
          <p className="font-[400] text-[16px]">Total Inquiries</p>
          <p className="text-[30px] font-bold text-black">
            {sumOfLeaderBoard.inquiries}
          </p>
        </div>
      </div>
      <div className="border-l border-l-[5px] border-red-600 h-14 flex items-center">
        <div className="pl-4 flex flex-col w-[280px] items-start justify-center">
          <p className="font-[400] text-[16px]">Total Pendings</p>
          <p className="text-[30px] font-bold text-black">
            {sumOfLeaderBoard.totalPending}
          </p>
        </div>
      </div>
      <div className="border-l border-l-[5px] border-[#0d1137] h-14 flex items-center">
        <div className="pl-4 flex flex-col w-[280px] items-start justify-center">
          <p className="font-[400] text-[16px]">Today Bookings</p>
          <p className="text-[30px] font-bold text-black">
            {sumOfLeaderBoard.todayBookings}
          </p>
        </div>
      </div>
      <div className="border-l border-l-[5px] border-[#077b8a] h-14 flex items-center">
        <div className="pl-4 flex flex-col w-[280px] items-start justify-center">
          <p className="font-[400] text-[16px]">Total Bookings</p>
          <p className="text-[30px] font-bold text-black">
            {sumOfLeaderBoard.todayBookings}
          </p>
        </div>
      </div>
      <div className="border-l border-l-[5px] border-[#cf1578] h-14 flex items-center">
        <div className="pl-4 flex flex-col w-[280px] items-start justify-center">
          <p className="font-[400] text-[16px]">Completed Bookings</p>
          <p className="text-[30px] font-bold text-black">
            {sumOfLeaderBoard.completeBookings}
          </p>
        </div>
      </div>
      <div className="border-l border-l-[5px] border-[#12a4d9] h-14 flex items-center">
        <div className="pl-4 flex flex-col w-[280px] items-start justify-center">
          <p className="font-[400] text-[16px]">Issued Bookings</p>
          <p className="text-[30px] font-bold text-black">
            {sumOfLeaderBoard.issuedBookings}
          </p>
        </div>
      </div>
      <div className="border-l border-l-[5px] border-[#6b7b8c] h-14 flex items-center">
        <div className="pl-4 flex flex-col w-[280px] items-start justify-center">
          <p className="font-[400] text-[16px]">Due Balance</p>
          <p className="text-[30px] font-bold text-black">
            {sumOfLeaderBoard.dueBalance}
          </p>
        </div>
      </div>
      <div className="border-l border-l-[5px] border-[#f3ca20] h-14 flex items-center">
        <div className="pl-4 flex flex-col w-[280px] items-start justify-center">
          <p className="font-[400] text-[16px]">Due Balance</p>
          <p className="text-[30px] font-bold text-black">
            {sumOfLeaderBoard.dueBalance}
          </p>
        </div>
      </div>
      <div className="border-l border-l-[5px] border-[#ff6e40] h-14 flex items-center">
        <div className="pl-4 flex flex-col w-[280px] items-start justify-center">
          <p className="font-[400] text-[16px]">Completed Profit</p>
          <p className="text-[30px] font-bold text-black">
            {sumOfLeaderBoard.completedProfit}
          </p>
        </div>
      </div>
      <div className="border-l border-l-[5px] border-[#ecc19c] h-14 flex items-center">
        <div className="pl-4 flex flex-col w-[280px] items-start justify-center">
          <p className="font-[400] text-[16px]">Cancelation Profit</p>
          <p className="text-[30px] font-bold text-black">
            {sumOfLeaderBoard.cancellationProfit}
          </p>
        </div>
      </div>
      <div className="border-l border-l-[5px] border-[#1868ae] h-14 flex items-center">
        <div className="pl-4 flex flex-col w-[280px] items-start justify-center">
          <p className="font-[400] text-[16px]">Total Profit</p>
          <p className="text-[30px] font-bold text-black">
            {sumOfLeaderBoard.totalProfit}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OverallStats;
