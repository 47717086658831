import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useApolloClient, useQuery } from "@apollo/client";
import {
  GET_SOCIAL_AUTHENTICATION_INFO,
  GET_SOCIAL_AUTHENTICATION_INFO_BRD_ID_AND_PLATFORM,
} from "~/graphql/brd_social_authentication/Query";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { Autocomplete } from "@mui/material";
import { AutocompleteChangeReason } from "@mui/material";

const SocialMediaPostSchedulerHeader = ({
  setSelectedPlatform,
  selectedPlatform,
  setSelectedBrandId,
  selectedBrandId,
  setAccess_token,
  access_token,
  id,
  setid,
}: {
  setSelectedPlatform: (platform: any) => void;
  selectedPlatform: any | null;
  setSelectedBrandId: (brandId: any | null) => void;
  selectedBrandId: any | null;
  setAccess_token: any | null;
  access_token: any | null;
  id: any | null;
  setid: any | null;
}) => {
  const { brands, brd_ids }: any = useAssigedBrands();
  const [brandIds, setBrandIds] = useState<any[]>([]);
  const { travelHouse }: any = travelHouseStore();
  const [selectedCustomer, setSelectedCustomer] = useState<any>([]);
  const [socialMediaPlatforms, setSocialMediaPlatforms] = useState<any[]>([]);
  const [accounts, setAccounts] = useState<any[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
  const client = useApolloClient();
  const condition = {
    _and: [
      { defaultRole: { _eq: "user" } },
      {
        brd_users: {
          thp_id: { _eq: travelHouse.id },
          brd_id: { _in: selectedCustomer?.id ? [selectedCustomer.id] : [] }, // Ensure it's an array
        },
      },
    ],
    id: selectedCustomer?.[0]?.id ? { _eq: selectedCustomer[0].id } : {},
  };

  const { data: socialAuthData, refetch } = useQuery(
    GET_SOCIAL_AUTHENTICATION_INFO,
    {
      variables: { brd_id: selectedBrandId },
      skip: !selectedBrandId,
    }
  );
  const token = socialAuthData?.brd_social_authentication[0]?.access_token;
  const profile_id = socialAuthData?.brd_social_authentication[0]?.profile_id;

  // setid(profile_id);
  setAccess_token(token);

  useEffect(() => {
    if (brd_ids && brd_ids?.length > 0) {
      setBrandIds(brd_ids);
    } else {
      setSelectedBrandId(null);
      setSocialMediaPlatforms([]);
      setAccounts([]);
    }
  }, [brd_ids]);

  useEffect(() => {
    if (socialAuthData && socialAuthData.brd_social_authentication) {
      // Map platforms and accounts from the query response
      const platforms = socialAuthData.brd_social_authentication.map(
        (item: any) => ({
          value: item.platform,
          label: item.platform,
        })
      );

      setSocialMediaPlatforms(platforms);

      // Set the default selected values if needed
      if (platforms.length > 0) {
        setSelectedPlatform(platforms[0].value);
      }
    } else {
      setSocialMediaPlatforms([]);
      setAccounts([]);
      setSelectedPlatform(null);
    }
  }, [socialAuthData, selectedBrandId]);
  useEffect(() => {
    console.log("dskjfgjdsf", selectedPlatform, id);

    const fetchSocialAuthDataWithPlatform = async () => {
      if (selectedBrandId && selectedPlatform) {
        try {
          const { data } = await client.query({
            query: GET_SOCIAL_AUTHENTICATION_INFO_BRD_ID_AND_PLATFORM,
            variables: { brd_id: selectedBrandId, platform: selectedPlatform },
          });

          if (data.brd_social_authentication) {
            const accounts = data.brd_social_authentication.map(
              (item: any) => ({
                value: item.profile_id,
                label: item.user_name || `Account ${item.profile_id}`,
              })
            );

            const profile_id = accounts[0]?.value;

            setid(profile_id);
            console.log("fasdfasdfads", id);
            setAccounts(accounts);
            // yaha add krna hai

            if (accounts.length > 0) {
              setSelectedAccount(accounts[0].value);
            }
          } else {
            setAccounts([]);
          }
        } catch (error) {
          console.error("Error fetching social auth data:", error);
        }
      }
    };

    fetchSocialAuthDataWithPlatform();
  }, [selectedBrandId, selectedPlatform]); // Updated dependency array

  const handleFlagChange = (
    event: React.SyntheticEvent,
    newValue: any | null,
    reason: AutocompleteChangeReason
  ) => {
    if (newValue) {
      setSelectedBrandId(newValue.id);
      setBrandIds([newValue.id]);
      refetch({ brd_id: newValue.id }); // Trigger refetching the social auth data
    } else {
      setSelectedBrandId(null);
      setBrandIds(brd_ids);
    }
  };
  useEffect(() => {
    if (selectedPlatform === "page") {
      setSelectedPlatform("organization");
    }
  }, [selectedPlatform, setSelectedPlatform]);

  return (
    <div className="p-4 bg-secondary dark:bg-dark-secondary block sm:flex items-center shadow rounded-t-lg justify-between lg:mt-1.5 relative">
      <Box
        component="div"
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)", // Changed to 3 columns
          gap: 2,
          width: "100%",
          alignItems: "center",
        }}
      >
        <div>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={brands}
            onChange={handleFlagChange}
            getOptionLabel={(option: any) => option?.name}
            sx={{ width: "100%" }}
            renderInput={(params): any => (
              <TextField {...params} label="Brands" />
            )}
          />
        </div>
        {/* Social Media Platform Select */}
        <TextField
          id="social-media-platform"
          label="Select Social Media"
          select
          //   placeholder="Select Social Media"
          sx={{ width: "100%" }}
          value={selectedPlatform || ""} // Controlled value
          onChange={(e) => setSelectedPlatform(e.target.value)}
        >
          {socialMediaPlatforms.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        {/* Select Account */}
        <TextField
          id="select-account"
          select
          label="Select Account"
          //   placeholder="Select an account"
          sx={{ width: "100%" }}
          value={selectedAccount || ""} // Controlled value
          onChange={(e) => setSelectedAccount(e.target.value)}
        >
          {accounts.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </div>
  );
};

export default SocialMediaPostSchedulerHeader;
