import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import {
  INSERT_BRD_SOCIAL_AUTHENTICATION,
  UPDATE_BRD_SOCIAL_AUTHENTICATION,
} from "~/graphql/brd_social_authentication/Mutation";

import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useUserDefaultRole } from "@nhost/react";

const VanityNamesDialog = ({
  open,
  onClose,
  vanityNames,

  stableToken,
}: any) => {
  const [insertSocialAuthentication] = useMutation(
    INSERT_BRD_SOCIAL_AUTHENTICATION
  );
  const role = useUserDefaultRole();
  const navigate = useNavigate();
  const { brd_id } = useParams();
  const handleConnect = async (id: any, name: any) => {
    if (stableToken && id && brd_id && name) {
      updateToken({
        variables: {
          profile_id: id,
          brd_id: brd_id,
          data: { access_token: stableToken },
        },
      }).then((data: any) => {
        if (data?.data?.update_brd_social_authentication?.affected_rows) {
          toast.success("Token Updated Successfully");
          onClose();
        } else {
          insertSocialAuthentication({
            variables: {
              data: {
                access_token: stableToken, // Add the relevant access token here
                profile_id: id,
                platform: "Page", // or other platform if needed
                accessToken_type: "3 Legged Organization", // or the actual type if different
                brd_id: brd_id,
                user_name: name,
                profile_picture: "dfasdfasd", // Add profile picture URL if available
              },
            },
          }).then((res: any) => {
            if (res?.data?.insert_brd_social_authentication?.affected_rows) {
              toast.success("LinkedIn Connected Successfully");
              onClose();
            }
          });
        }
      });
    }
  };

  const [insertToken] = useMutation(INSERT_BRD_SOCIAL_AUTHENTICATION);
  const [updateToken] = useMutation(UPDATE_BRD_SOCIAL_AUTHENTICATION);

  // Handle token insertion and update

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Vanity Names</DialogTitle>
      <DialogContent>
        <TableContainer
          component={Paper}
          className="bg-secondary dark:bg-dark-secondary"
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="text-primary dark:text-dark-primary">
                  Organization ID
                </TableCell>
                <TableCell className="text-primary dark:text-dark-primary">
                  Vanity Name
                </TableCell>
                <TableCell className="text-primary dark:text-dark-primary">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(vanityNames).length > 0 ? (
                Object.entries(vanityNames).map(([id, name]: any) => (
                  <TableRow key={id}>
                    <TableCell>{id}</TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleConnect(id, name)}
                      >
                        Connect
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} className="text-center">
                    No vanity names available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default VanityNamesDialog;
