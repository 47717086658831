import { gql } from "@apollo/client";

export const GET_SOCIAL_AUTHENTICATION_INFO = gql`
  query GetSocialAuthenticationInfo($brd_id: uuid!) {
    brd_social_authentication(where: { brd_id: { _eq: $brd_id } }) {
      expiry
      updated_at
      accessToken_type
      platform
      profile_id
      profile_picture
      user_name
      access_token
    }
  }
`;

export const GET_SOCIAL_AUTHENTICATION_INFO_BRD_ID_AND_PLATFORM = gql`
  query GetSocialAuthenticationInfo($brd_id: uuid!, $platform: String!) {
    brd_social_authentication(
      where: { brd_id: { _eq: $brd_id }, platform: { _eq: $platform } }
    ) {
      expiry
      updated_at
      accessToken_type
      platform
      profile_id
      profile_picture
      user_name
      access_token
    }
  }
`;
