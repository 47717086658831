import React, { useState } from "react";
import PostDetailsForm from "./PostDetailsForm";
import SocialMediaPostSchedulerHeader from "./SocialMediaPostSchedulerHeader";

const PostScheduler = () => {
  const [selectedPlatform, setSelectedPlatform] = useState<string | null>(null);
  const [selectedBrandId, setSelectedBrandId] = useState<string | null>(null);
  const [access_token, setAccess_token] = useState<string | null>(null);
  const [id, setid] = useState<String | null>(null);

  return (
    <>
      <SocialMediaPostSchedulerHeader
        setSelectedPlatform={setSelectedPlatform}
        selectedPlatform={selectedPlatform}
        selectedBrandId={selectedBrandId}
        setSelectedBrandId={setSelectedBrandId}
        setAccess_token={setAccess_token}
        access_token={access_token}
        id={id}
        setid={setid}
      />
      <PostDetailsForm
        setSelectedPlatform={setSelectedPlatform}
        selectedPlatform={selectedPlatform}
        selectedBrandId={selectedBrandId}
        setSelectedBrandId={setSelectedBrandId}
        setAccess_token={setAccess_token}
        access_token={access_token}
        id={id}
        setid={setid}
      />
    </>
  );
};

export default PostScheduler;
