import { useQuery } from "@apollo/client";
import { Autocomplete, Box, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GET_TEMPLATES } from "~/graphql/actions/whatsapp/whatsapp_templates_get_all";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import useWhatsappTemplates from "~/hooks/useWhatsappTemplates";
import TemplateRow from "~/modules/WhatsappModule/segments/WhatsappTemplates/components/TemplateRow";
import TemplateRowSekeleton from "~/modules/WhatsappModule/segments/WhatsappTemplates/components/TemplateRowSekeleton";

export default function TemplateList({ templateSearchText }: any) {
  const { brd_ids, brands }: any = useAssigedBrands();

  const [brdIds, setBrdIds] = useState<any>(brd_ids);

  useEffect(() => {
    if (!brdIds?.[0] || !brdIds) {
      setBrdIds(brd_ids);
    }
  }, [brdIds, brd_ids]);

  const { templates }: any = useWhatsappTemplates(brdIds && brdIds);

  return (
    <>
      <div className="px-[20px] pt-[20px] pb-[10px] w-full bg-whatsappSecondaryBg dark:bg-dark-secondary">
        <Autocomplete
          disablePortal
          onChange={(_, data: any) => {
            setBrdIds([data?.id]);
          }}
          options={brands ? brands : []}
          getOptionLabel={(option: any) => option?.name}
          renderOption={(props, option: any) => (
            <Box component="li" {...props}>
              {option?.name}
            </Box>
          )}
          renderInput={(params) => <TextField label="Brand" {...params} />}
          noOptionsText=""
        />
      </div>
      <div className="text-primary dark:text-dark-primary overflow-scroll flex-grow">
        {templates?.length <= 0 ? (
          <>
            <TemplateRowSekeleton />
            <TemplateRowSekeleton />
            <TemplateRowSekeleton />
            <TemplateRowSekeleton />
            <TemplateRowSekeleton />
            <TemplateRowSekeleton />
            <TemplateRowSekeleton />
            <TemplateRowSekeleton />
            <TemplateRowSekeleton />
            <TemplateRowSekeleton />
          </>
        ) : (
          templates?.map((template: any) => {
            return <TemplateRow template={template} />;
          })
        )}
      </div>
    </>
  );
}
