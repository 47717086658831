import React from "react";

interface ConfrimPopupProps {
  confirm: any;
  closeModal: any;
}

const ConfrimPopup = ({ confirm, closeModal }: ConfrimPopupProps) => {
  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black/70"
      style={{ zIndex: 50 }}
    >
      <div className="relative bg-white dark:bg-dark-primary text-primary dark:text-dark-primary rounded-lg shadow-2xl p-8 w-11/12 sm:w-3/4 md:w-1/2 lg:w-1/3">
        <h2 className="mb-6 text-2xl font-bold text-gray-700 dark:text-dark-primary">Are you sure you want to generate invoice?</h2>
        <div className="flex justify-end space-x-4">
          <button
            onClick={closeModal}
            className="px-5 py-1 font-semibold text-basic bg-white dark:bg-dark-primary border border-basic rounded-lg"
          >
            No
          </button>
          <button
            onClick={() => {
              confirm();
              closeModal();
            }}
            className="px-5 py-1 font-semibold text-white bg-basic rounded-lg"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfrimPopup;
