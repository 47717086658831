import Layout from "~/components/common/Layout";

import PostSchedulerPage from "~/modules/BrandModule/modules/SocialConnectModule/components/PostSchedulerPage";

export default function GoogleCampaignPage() {
  return (
    <div>
      <Layout>
        {/* <BrandModule> */}
        <PostSchedulerPage />
        {/* </BrandModule> */}
      </Layout>
    </div>
  );
}
