import React, { useState } from "react";
import InvoiceCard from "./components/InvoiceCard";
import PaymentCard from "./components/PaymentCard";
import PaymentAdd from "./components/PaymentAdd";
import { toast } from "react-toastify";
import { ADD_INVOICE, EDIT_INQUIRY } from "./graphql/Mutation";
import { useMutation } from "@apollo/client";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import PaymentRequest from "./components/PaymentRequest";
import RefundPopup from "./components/RefundPopup";
import SideDrawer from "~/components/common/sideDrawer";

export default function PaymentsCard({ inquiry }: any) {
  const role = useUserDefaultRole();
  const user_id = useUserId();
  const [hideForm, setHideForm] = useState(false);
  const [requestForm, setRequestForm] = useState(false);
  const [openRefMod, setOpenRefMod] = useState(false);
  const [sidePopup, setSidePopup] = useState(false);
  const [addInvoice] = useMutation(ADD_INVOICE);
  const [edit] = useMutation(EDIT_INQUIRY);

  function generateRandomNumber() {
    return Math.floor(Math.random() * 1000000); // Adjust the range as needed
  }

  return (
    <div className="self-stretch flex flex-col items-start justify-start gap-[30px] mb-5">
      {/* <div className="self-stretch flex  pt-0 px-0 pb-[5px] items-center justify-between border-b-[1px] border-solid border-[#E7E3FC61]">
        <p className="text-basic font-medium mt-2">{`Payments`}</p>
        {inquiry.status !== "completed" && inquiry.status !== "refunded" && (
          <div className="flex gap-3">
            {(user_id === inquiry?.picked_by || role === "admin") && (
              <div className="flex justify-end gap-3 w-full">
                <button
                  onClick={() => setHideForm(true)}
                  className="block border border-basic w-[160px] font-medium rounded-full px-3 py-1 text-white cursor-pointer bg-basic hover:bg-white dark:hover:bg-dark-primary hover:text-basic"
                >
                  Add Payment
                </button>
              </div>
            )}

            {(user_id === inquiry?.picked_by || role === "admin") && (
              <div className="flex justify-end gap-3 w-full">
                <button
                  onClick={() => setRequestForm(true)}
                  className="block border border-basic w-[160px] font-medium rounded-full px-3 py-1 text-white cursor-pointer bg-basic hover:bg-white dark:hover:bg-dark-primary hover:text-basic"
                >
                  Payment Request
                </button>
              </div>
            )}
            <div className="flex justify-end gap-3 w-full">
              <button
                onClick={() => setOpenRefMod(true)}
                className="block border border-basic w-[160px] font-medium rounded-full px-3 py-1 text-white cursor-pointer bg-basic hover:bg-white dark:hover:bg-dark-primary hover:text-basic"
              >
                Refund and Close
              </button>
            </div>
            <RefundPopup
              open={openRefMod}
              setOpen={setOpenRefMod}
              inquiry={inquiry}
            />
          </div>
        )}
      </div> */}

      <div className="self-stretch flex items-center dark:text-primary mt-[30px]">
        <hr className="w-[2%] dark:border-[#E7E3FC61]" />
        <span className="text-[14px] text-secondary dark:text-dark-secondary min-w-fit text-center px-2">
          PAYMENTS
        </span>
        <hr className="w-full dark:border-[#E7E3FC61]" />
        {inquiry.status !== "completed" && inquiry.status !== "refunded" && (
          <div className="flex gap-3 pl-2 min-w-fit">
            {(user_id === inquiry?.picked_by || role === "admin") && (
              <div className="flex justify-end gap-3 w-full">
                <button
                  onClick={() => setHideForm(true)}
                  className="block border border-basic w-[160px] font-medium rounded-full px-3 py-1 text-white cursor-pointer bg-basic hover:bg-white dark:hover:bg-dark-primary hover:text-basic"
                >
                  Add Payment
                </button>
              </div>
            )}

            {(user_id === inquiry?.picked_by || role === "admin") && (
              <div className="flex justify-end gap-3 w-full">
                <button
                  onClick={() => setRequestForm(true)}
                  className="block border border-basic w-[160px] font-medium rounded-full px-3 py-1 text-white cursor-pointer bg-basic hover:bg-white dark:hover:bg-dark-primary hover:text-basic"
                >
                  Payment Request
                </button>
              </div>
            )}
            <div className="flex justify-end gap-3 w-full">
              <button
                onClick={() => setSidePopup(true)}
                className="block border border-basic w-[160px] font-medium rounded-full px-3 py-1 text-white cursor-pointer bg-basic hover:bg-white dark:hover:bg-dark-primary hover:text-basic"
              >
                Refund and Close
              </button>
            </div>
            <SideDrawer
              setSidePopup={setSidePopup}
              sidePopup={sidePopup}
              component={
                <RefundPopup
                  inquiry={inquiry}
                  setSidePopup={setSidePopup}
                />
              }
            />

          </div>
        )}
      </div>

      <div className="self-stretch grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3  items-start justify-start gap-[30px] text-mini">
        {inquiry?.inq_transections.map((transaction: any, i: any) => (
          <PaymentCard key={i} inquiry={inquiry} transaction={transaction} />
        ))}

        {hideForm && (
          <SideDrawer
            setSidePopup={setHideForm}
            sidePopup={hideForm}
            component={
              <PaymentAdd
                setHideForm={setHideForm}
                inq={inquiry}
                payment_method_id={inquiry.payment_method_id}
                user={inquiry.users}
                inquiry_id={inquiry.id}
                brd_domain={inquiry.brd_list.domain}
                brd_id={inquiry.brd_id}
                picked_by={inquiry.picked_by}
              />
            }
          />
        )}

        {requestForm && (
          <SideDrawer
            setSidePopup={setRequestForm}
            sidePopup={requestForm}
            component={
              <PaymentRequest
                setHideForm={setRequestForm}
                inq={inquiry}
                payment_method_id={inquiry.payment_method_id}
                user={inquiry.users}
                inquiry_id={inquiry.id}
                brd_domain={inquiry.brd_list.domain}
                brd_id={inquiry.brd_id}
                picked_by={inquiry.picked_by}
              />
            }
          />
        )}
      </div>
    </div>
  );
}
