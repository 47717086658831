import { Button } from "@mui/material";
import React from "react";
import LinkedInUserLogIn from "./LinkedInUserLogIn";
import LinkedInOrganizationLogIn from "./LinkedInOrganizationLogIn";
import WhatsAppLogIn from "./WhatsAppLogIn";

const SocialPopup = () => {
  return (
    <div className="md:w-[500px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-3xl text-basic mb-6">
        Connect Social Accounts
      </h1>
      <div className="flex flex-col gap-2">
        <LinkedInUserLogIn />
        <LinkedInOrganizationLogIn />
        <WhatsAppLogIn />
      </div>
    </div>
  );
};

export default SocialPopup;
