import React, { useState } from "react";
import PaymentDetail from "~/components/common/PaymentDetail";
import { Controller, useForm } from "react-hook-form";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AddCardIcon from "@mui/icons-material/AddCard";
import CancelIcon from "@mui/icons-material/Cancel";
import { stripe } from "~/lib/stripe";
import { toast } from "react-toastify";
import { useApolloClient, useQuery } from "@apollo/client";
import PlanCredits from "~/modules/DefTablesModule/segments/DefPlanCredits/components/PlanCredits";
import { GET_DEF_CREDITS_PLAN_WITH_ID } from "~/graphql/def_credits_plan/Query";

const AddCredits = ({ openMod, setOpenMod, curPlan, defPaymentMeth }: any) => {
  const [addPayMeth, setAddPayMeth] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data } = useQuery(GET_DEF_CREDITS_PLAN_WITH_ID, {
    variables: {
      planID: curPlan.plan_id,
    },
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <div>
      <h1 className="text-center font-bold text-[25px] my-5">
        ADD CREDITS ({openMod})
      </h1>
      <div className="mt-7 flex items-center gap-[30px] justify-center">
        {data?.def_credits_plan?.map((item: any, ind: any) => {
          return <PlanCredits key={ind} data={item} type="Add Credit" />;
        })}
      </div>
    </div>
  );
};

export default AddCredits;
