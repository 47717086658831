import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useApolloClient } from "@apollo/client";
import { CREATE_LINKEDIN_POST } from "~/graphql/create_linkedin_post/Query";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const PostDetailsForm = ({
  setSelectedPlatform,
  selectedPlatform,
  setSelectedBrandId,
  selectedBrandId,
  setAccess_token,
  access_token,
  id,
  setid,
}: {
  setSelectedPlatform: (platform: any) => void;
  selectedPlatform: any | null;
  setSelectedBrandId: (brandId: any | null) => void;
  selectedBrandId: any | null;
  setAccess_token: any | null;
  access_token: any | null;
  id: any | null;
  setid: any | null;
}) => {
  const [postContent, setPostContent] = useState("");
  const [postType, setPostType] = useState("POST");
  const [postStatus, setPostStatus] = useState("PUBLISHED");
  const [visibility, setVisibility] = useState("PUBLIC");
  const [articleFields, setArticleFields] = useState(() => ({
    articleTitle: postType === "POST" ? "" : "default value",
    articleDescription: postType === "POST" ? "" : "default value",
    articleStatus: postType === "POST" ? "READY" : "default value",

    articleUrl: postType === "POST" ? "" : "default value",
  }));
  // const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageBase64, setImageBase64] = useState<string | null>(null);
  const client = useApolloClient();
  const platformToUse =
    selectedPlatform === "Page"
      ? "organization"
      : selectedPlatform === "LinkedIn"
      ? "person"
      : selectedPlatform;

  const convertImageToBase64 = (file: File) => {
    return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const handlePost = async () => {
    try {
      const res = await client.query({
        query: CREATE_LINKEDIN_POST,
        variables: {
          access_token: access_token,
          id: id,
          author: platformToUse,
          lifecycleState: postStatus,
          shareCommentaryText: postContent,
          shareMediaCategory: postType,
          mediaStatus: articleFields.articleStatus || "NONE",
          mediaDescription: articleFields.articleDescription || "NONE",
          mediaTitleText: articleFields.articleTitle || "NONE",
          mediaOrgURL: articleFields.articleUrl || "NONE",
          visibility,
          mediaImage: imageBase64 || "file base 64", // Use base64 string
        },
      });
      console.log("res", res.data);
    } catch (error) {
      console.log("res", error);
    }
  };

  return (
    <div className="p-4 bg-light dark:bg-dark block sm:flex flex-col items-start shadow rounded-b-lg justify-between mt-4 relative">
      <Box
        component="div"
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gap: 2,
          width: "100%",
        }}
      >
        <TextField
          id="post-type"
          select
          label="Select Post Type"
          value={postType}
          onChange={(e) => setPostType(e.target.value)}
          sx={{ width: "100%" }}
        >
          <MenuItem value="ARTICLE">Article</MenuItem>
          <MenuItem value="POST">Post</MenuItem>
          <MenuItem value="IMAGE">Image</MenuItem>
        </TextField>

        <TextField
          id="post-status"
          select
          label={
            postType === "ARTICLE"
              ? "Select Article Status"
              : "Select Post Status"
          }
          value={postStatus}
          onChange={(e) => setPostStatus(e.target.value)}
          sx={{ width: "100%" }}
        >
          <MenuItem value="DRAFT">Draft</MenuItem>
          <MenuItem value="PUBLISHED">Publish</MenuItem>
          <MenuItem value="ARCHIVED">Archive</MenuItem>
        </TextField>

        <TextField
          id="post-visibility"
          select
          label="Who would you like to share with?"
          value={visibility}
          onChange={(e) => setVisibility(e.target.value)}
          sx={{ width: "100%" }}
        >
          <MenuItem value="PUBLIC">Public</MenuItem>
          <MenuItem value="CONNECTIONS">Connections</MenuItem>
        </TextField>
      </Box>

      <Box
        component="div"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "100%",
          marginTop: 2,
        }}
      >
        <TextField
          id="post-content"
          label={postType === "ARTICLE" ? "Article Content" : "Post Content"}
          placeholder={
            postType === "ARTICLE"
              ? "Enter the content of your article"
              : "Enter the content of your post"
          }
          multiline
          rows={4}
          value={postContent}
          onChange={(e) => setPostContent(e.target.value)}
          sx={{ width: "100%" }}
        />

        {postType === "ARTICLE" && (
          <Box
            component="div"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "100%",
              marginTop: 2,
            }}
          >
            <TextField
              id="article-title"
              label="Article Title"
              value={articleFields.articleTitle}
              onChange={(e) =>
                setArticleFields({
                  ...articleFields,
                  articleTitle: e.target.value,
                })
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="article-description"
              label="Article Description"
              value={articleFields.articleDescription}
              onChange={(e) =>
                setArticleFields({
                  ...articleFields,
                  articleDescription: e.target.value,
                })
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="article-url"
              label="Article URL"
              value={articleFields.articleUrl}
              onChange={(e) =>
                setArticleFields({
                  ...articleFields,
                  articleUrl: e.target.value,
                })
              }
              sx={{ width: "100%" }}
            />
          </Box>
        )}
        {(postType === "ARTICLE" || postType === "IMAGE") && (
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
            sx={{
              marginTop: 2,
              maxWidth: 200, // Set maximum width for the button
              width: "auto", // Allow button to fit its content
              display: "inline-flex", // Adjust the display to fit content
            }}
          >
            Upload Image
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={async (event) => {
                if (event.target.files && event.target.files[0]) {
                  const file = event.target.files[0];
                  // setImageFile(file);
                  const base64 = await convertImageToBase64(file);
                  setImageBase64(base64 as string);
                  console.log("Base64 String:", base64);
                }
              }}
            />
          </Button>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={handlePost}
          sx={{
            marginTop: 2,
            maxWidth: 200, // Set maximum width for the button
            width: "auto", // Allow button to fit its content
            display: "inline-flex", // Adjust the display to fit content
          }}
        >
          Post Now
        </Button>
      </Box>
    </div>
  );
};

export default PostDetailsForm;
