import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Switch,
  TextField,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import _airlineData from "../../../../components/FormInputs/AirlineBox/data/airlines.json";
import useAssignedUsers from "~/hooks/useAssignedUsers";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useApolloClient, useMutation } from "@apollo/client";
import { INSERT_INQUIRY } from "./graphql/Mutation";
import { generateRandomNumberString } from "~/utils/generateRandomId";
import moment from "moment";
import { toast } from "react-toastify";
import useBrandPaymentMethod from "~/hooks/useBrandPaymentMethod";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import LocationBox from "~/components/FormInputs/LocationBox/LocationBox";

const InquiryAdd = ({ setSidePopup }: any) => {
  const { brands }: any = useAssigedBrands();
  const [formValue, setFormValue] = useState({
    brand: "",
    user: "",
    paymentMethod: "",
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const tripType = watch("tripType");

  const foundBrand =
    brands && brands.find((brand: any) => brand.name === formValue.brand);
  const brd_id = foundBrand ? foundBrand.id : null;

  const { users }: any = useAssignedUsers(brd_id);

  const foundUser =
    users && users.find((user: any) => user.displayName === formValue.user);
  const uid = foundUser ? foundUser.id : null;

  const { paymentMethod }: any = useBrandPaymentMethod(brd_id);

  const foundPaymentMethod =
    paymentMethod &&
    paymentMethod.find(
      (paymentMethod: any) => paymentMethod.name === formValue.paymentMethod
    );
  const pid = foundPaymentMethod ? foundPaymentMethod.id : null;

  const [directFlight, setDirectFlight] = useState(false);
  const [flexibleDeparture, setFlexibleDeparture] = useState(false);
  const [flexibleArrival, setFlexibleArrival] = useState(false);
  const [insertInquiry] = useMutation(INSERT_INQUIRY);
  const client = useApolloClient();
  const { travelHouse }: any = travelHouseStore();

  const onSubmit = async (data: any) => {
    try {
      const res = await insertInquiry({
        variables: {
          data: {
            from: data?.from,
            to: data?.to,
            flexible_arrival: data?.flexibleArrival,
            flexible_departure: data?.flexibleDeparture,
            direct_flight: data?.directFlight,
            preferred_airline: data?.airline,
            approx_budget: data?.approxBudget,
            payment_method_id: pid,
            cabin_class: data?.cabinClass,
            trip_type: data?.tripType,
            flag_id: 64,
            status: "pending",
            brd_id: brd_id,
            inquiry_no: generateRandomNumberString(),
            thp_id: travelHouse?.id,
            user_id: uid,
            departure_datetime: moment(data?.departureDate)?.format(
              "YYYY-MM-DD"
            ),
            arrival_datetime: moment(data?.arrivalDate)?.format("YYYY-MM-DD"),
            created_at: moment(data?.created_at)?.format("YYYY-MM-DD"),
            inq_passengers: {
              data: {
                first_name: "",
                type: "adult",
              },
            },
          },
        },
      });
      if (res?.data?.insert_inq_list_one?.id) {
        toast.success("Inquiry  inserted  successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
        setSidePopup(false);
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="md:w-[600px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-3xl text-basic">Add Inquiry</h1>
        <Divider />
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("brand", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data?.name);
                    setFormValue({
                      ...formValue,
                      brand: data?.name,
                    });
                  }}
                  options={brands ? brands : []}
                  getOptionLabel={(option: any) => option?.name}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option?.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label="Brand"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                        el && errors["brand"] && el.focus();
                      }}
                      error={errors["brand"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("user", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data?.displayName);
                    setFormValue({
                      ...formValue,
                      user: data?.displayName,
                    });
                  }}
                  options={users ? users : []}
                  getOptionLabel={(option: any) => option?.displayName}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option?.displayName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label="User"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                      }}
                      error={errors["user"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />
            <LocationBox
              control={control}
              register={register}
              errors={errors}
              fieldName="from"
              label="From"
            />
            <LocationBox
              control={control}
              register={register}
              errors={errors}
              fieldName="to"
              label="To"
            />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("cabinClass", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data);
                  }}
                  options={["Economy", "Business"]}
                  getOptionLabel={(option: any) => option}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label="Cabin Class"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                      }}
                      error={errors["cabinClass"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("tripType", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data?.value);
                  }}
                  options={[
                    { name: "One Way", value: "oneWay" },
                    { name: "Round Trip", value: "roundTrip" },
                  ]}
                  getOptionLabel={(option: any) => option.name}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label="Trip Type"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                      }}
                      error={errors["tripType"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("airline", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data?.marketing_name);
                  }}
                  filterOptions={(options, { inputValue }: any) => {
                    // If input is empty, don't show any suggestions
                    if (!inputValue.trim()) {
                      return [];
                    }

                    // Filter options to only include those that start with the user's input
                    const inputValueLowerCase = inputValue.toLowerCase();
                    return options.filter((option) =>
                      option.marketing_name
                        .toLowerCase()
                        .startsWith(inputValueLowerCase)
                    );
                  }}
                  options={_airlineData ? _airlineData : []}
                  getOptionLabel={(option: any) => option.marketing_name}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option.marketing_name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label="Airline"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                      }}
                      error={errors["airline"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />

            <TextField
              error={errors["approxBudget"] ? true : false}
              {...register("approxBudget", { required: true })}
              label="Approx Budget"
              type="number"
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker", "DatePicker"]}>
                <Controller
                  rules={{
                    required: true,
                  }}
                  {...register("departureDate", { required: true })}
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DatePicker
                      value={value}
                      inputRef={ref}
                      onChange={(date) => {
                        onChange(date?.$d);
                      }}
                      sx={{ width: "100%" }}
                      label="Departure Date"
                      slotProps={{
                        textField: {
                          error: errors["departureDate"] ? true : false,
                        },
                      }}
                    />
                  )}
                />
              </DemoContainer>
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker", "DatePicker"]}>
                <Controller
                  rules={{
                    required: true,
                  }}
                  {...register("created_at", { required: true })}
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DatePicker
                      value={value}
                      inputRef={ref}
                      onChange={(date) => {
                        onChange(date?.$d);
                      }}
                      sx={{ width: "100%" }}
                      label="Created At"
                      slotProps={{
                        textField: {
                          error: errors["created_at"] ? true : false,
                        },
                      }}
                    />
                  )}
                />
              </DemoContainer>
            </LocalizationProvider>

            {tripType === "roundTrip" && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker", "DatePicker"]}>
                  <Controller
                    rules={{
                      required: true,
                    }}
                    {...register("arrivalDate", { required: true })}
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <DatePicker
                        value={value}
                        inputRef={ref}
                        onChange={(date) => {
                          onChange(date?.$d);
                        }}
                        sx={{ width: "100%" }}
                        label="Arrival Date"
                        slotProps={{
                          textField: {
                            error: errors["arrivalDate"] ? true : false,
                          },
                        }}
                      />
                    )}
                  />
                </DemoContainer>
              </LocalizationProvider>
            )}
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("paymentMethod", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  sx={{ marginTop: "7px" }}
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data?.name);
                    setFormValue({
                      ...formValue,
                      paymentMethod: data?.displayName,
                    });
                  }}
                  options={paymentMethod ? paymentMethod : []}
                  getOptionLabel={(option: any) => option?.name}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option?.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label="Payment Method"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                      }}
                      error={errors["paymentMethod"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />
            <div className="flex justify-between items-center">
              <label>Flexible Departure</label>
              <Switch
                {...register("flexibleDeparture")}
                value={flexibleDeparture}
                checked={flexibleDeparture}
                onChange={() => setFlexibleDeparture(!flexibleDeparture)}
              />
            </div>
            <div className="flex justify-between items-center">
              <label>Flexible Arrival</label>
              <Switch
                {...register("flexibleArrival")}
                value={flexibleArrival}
                checked={flexibleArrival}
                onChange={() => setFlexibleArrival(!flexibleArrival)}
              />
            </div>

            <div className="flex justify-between items-center">
              <label>Direct Flight</label>
              <Switch
                {...register("directFlight")}
                value={directFlight}
                checked={directFlight}
                onChange={() => setDirectFlight(!directFlight)}
              />
            </div>
          </div>
          <Button
            type="submit"
            sx={{ width: "100%", marginTop: "25px" }}
            variant="contained"
          >
            Add
          </Button>
        </Box>
      </div>
    </>
  );
};

export default InquiryAdd;
