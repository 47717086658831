import { gql } from "@apollo/client";

export const GET_TRAVEL_HOUSE = gql`
  query GetTravelHouse($user_id: uuid!, $custom_url: String!) {
    thp_list(
      where: {
        _or: [
          { domain: { _eq: $custom_url } }
          { subdomain: { _eq: $custom_url } }
        ]
        _and: {
          _or: [
            { brd_users: { users: { id: { _eq: $user_id } } } }
            { primary_user: { id: { _eq: $user_id } } }
          ]
        }
      }
    ) {
      id
      plan_id
      name
      yearly
      subscription_ref
      subdomain
      brd_users(where: { user_id: { _eq: $user_id } }) {
        brd_id
      }
      domain
      email_refresh_token
      disabled
      suspended_at
      activated_at
      expiry
      created_at
      updated_at
      invoice_credits
    }
  }
`;
