import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import ViewIcon from "modules/InquiriesModule/segments/InquiryDetail/assets/svg/ViewIcon";
import React, { useState, useEffect } from "react";
import {
  ADD_TRANSACTION,
  EDIT_INQUIRY,
  EDIT_TRANSACTION,
} from "./graphql/Mutation";
import { toast } from "react-toastify";
import useNotifications from "hooks/useNotifications";
import { SEND_EMAIL } from "~/config/Mutation";
import moment from "moment";
import { GET_TRAVEL_HOUSE_BY_ID } from "~/modules/InboxModule/segments/Inbox/graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { useUserData, useUserDefaultRole } from "@nhost/react";
import useEmail from "~/hooks/emails/useEmail";
import { URLs } from "~/config/enums";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { nhost } from "~/lib/nhost";
import RecieptPdf from "./components/RecieptPdf";
import { pdf } from "@react-pdf/renderer";
import { GET_INQ } from "~/graphql/inq_list/Query";
import { Link } from "react-router-dom";

export default function PaymentRecord({ index, transaction }: any) {
  const [modalOpen, setModelOpen] = useState(false);
  const [showReceipt, setShowReceipt] = useState(false);
  const [date, setDate] = useState<any>(new Date());
  const [charges, setCharges] = useState<any>();

  const role = useUserDefaultRole();

  const [isLoading, setIsLoading] = useState(false);
  const client = useApolloClient();
  const { travelHouse }: any = travelHouseStore();
  const userData = useUserData();
  const [sendEmail] = useMutation(SEND_EMAIL);

  const { loading, data, error } = useSubscription(GET_INQ, {
    variables: { id: transaction?.inq_list?.id },
  });

  const [logo, setLogo] = useState<any>();

  async function convertLogo() {
    try {
      const response = await fetch(
        URLs.FILE_URL +
          data?.inq_list?.[0]?.brd_list?.brd_details?.[0]?.fav_icon
      );
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result);
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  }

  useEffect(() => {
    convertLogo();
  }, [data?.inq_list?.[0]?.brd_list?.brd_details?.[0]?.fav_icon]);

  const uploadPdfToNhost = async (file: any) => {
    try {
      const response = await nhost.storage.upload({
        file,
      });

      if (response.error) {
        console.error("Upload error:", response.error.message);
        throw new Error(response.error.message);
      }

      return response.fileMetadata.id;
    } catch (error) {
      console.error("Failed to upload PDF:", error);
      throw error;
    }
  };

  const emailSend = useEmail();
  const { loading: travelHouseLoading, data: travelHouseData } = useQuery(
    GET_TRAVEL_HOUSE_BY_ID,
    {
      variables: { id: travelHouse?.id },
      fetchPolicy: "network-only",
    }
  );

  const [editTransaction] = useMutation(EDIT_TRANSACTION);
  const [editInquiry] = useMutation(EDIT_INQUIRY);
  const [addTransaction] = useMutation(ADD_TRANSACTION);

  const alert = useNotifications();

  // Confirm payment
  const execConfirm = async () => {
    const blob = await pdf(
      <RecieptPdf
        inquiry={data?.inq_list?.[0]}
        invoice={data?.inq_list?.[0]?.inq_invoice}
        logo={logo}
        transID={transaction?.id}
      />
    ).toBlob();

    // Step 2: Convert Blob to File
    const file = new File([blob], "invoice.pdf", {
      type: "application/pdf",
    });

    const pdfId = await uploadPdfToNhost(file);

    setModelOpen(false);
    setIsLoading(true);

    if (!date) {
      toast.error("Please Select Date");
      return;
    }

    const transactions: any = [
      {
        brd_id: transaction?.inq_list.brd_id,
        def_acc_id: transaction?.acc_payment_method?.type === "bank" ? 3 : 14,
        inq_id: transaction.inq_list.id,
        payment_method_id: transaction.acc_payment_method.id,
        transactions_no: transaction.transactions_no,
        date: moment(date).format("YYYY-MM-DD"),
        type: "debit",
        amount: parseFloat(transaction.amount) - parseFloat(charges),
        created_at: moment(date).format("YYYY-MM-DD"),
      },
      {
        brd_id: transaction?.inq_list.brd_id,
        def_acc_id: 4,
        user_id: transaction.inq_list.users.id,
        inq_id: transaction.inq_list.id,
        transactions_no: transaction.transactions_no,
        date: moment(date).format("YYYY-MM-DD"),
        type: "credit",
        amount: -transaction.amount,
        created_at: moment(date).format("YYYY-MM-DD"),
      },
    ];

    if (parseFloat(charges) !== 0) {
      if (transaction?.acc_payment_method?.type === "bank") {
        transactions.push({
          brd_id: transaction?.inq_list.brd_id,
          def_acc_id: 12,
          user_id: transaction.inq_list.users.id,
          inq_id: transaction.inq_list.id,
          transactions_no: transaction.transactions_no,
          date: moment(date).format("YYYY-MM-DD"),
          type: "debit",
          amount: parseFloat(charges),
        });
      } else if (
        transaction?.acc_payment_method?.card_terminal_type === "Manual"
      ) {
        transactions.push({
          brd_id: transaction?.inq_list.brd_id,
          def_acc_id: 13,
          user_id: transaction.inq_list.users.id,
          inq_id: transaction.inq_list.id,
          transactions_no: transaction.transactions_no,
          date: moment(date).format("YYYY-MM-DD"),
          type: "debit",
          amount: parseFloat(charges),
        });
      }
    }

    if (
      transactions[0].amount +
        (transactions?.[2]?.amount || 0) +
        transactions[1].amount ===
      0
    ) {
      try {
        const res = await addTransaction({
          variables: {
            transactions: transactions,
          },
        });
        toast.success("Transaction added successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.resetStore();

        const payload = {
          id: transaction.id,
          status: "paid",
          receipt: URLs.FILE_URL + pdfId,
        };

        try {
          const res = await editTransaction({ variables: payload });
          if (res.data?.update_inq_transection?.returning?.length > 0) {
            const res = await editInquiry({
              variables: { id: transaction.inq_list.id },
            });

            const emailVariables: any = {
              inquiryNo: transaction.inq_list?.inquiry_no,
              userFirstName: transaction.inq_list?.users?.displayName,
              userLastName: "",
              consultantName: transaction.inq_list.picked_user?.displayName,
              brandName: transaction.inq_list?.brd_list?.name,
              amount: transaction.amount,
              dateOfPayment: moment(transaction.created_at).format(
                "DDD-MM-yyyy"
              ),
              fileLink: URLs.SAMPLE_PDF,
              fileName: `PAYMENT-${transaction.amount}`,
            };

            await emailSend(
              9,
              transaction.inq_list.brd_id,
              transaction.inq_list.users?.id,
              transaction.inq_list.users?.email,
              transaction.inq_list.users?.phoneNumber,
              emailVariables
            );

            await emailSend(
              24,
              transaction.inq_list.brd_id,
              transaction.inq_list.picked_by,
              transaction.inq_list.users?.email,
              transaction.inq_list.users?.phoneNumber,
              emailVariables
            );

            toast.success("Payment Status Updated Successfully", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            // ;
            await alert.newNotification(
              transaction?.inq_list?.picked_by,
              `Your customer payment approved by accountant, please check your portal`,
              "",
              ``
            );
            await alert.newNotification(
              transaction?.inq_list?.user_id,
              `Your payment has approved by account department, please check your portal`,
              "",
              ``
            );

            setIsLoading(false);
            await client.resetStore();
          }
        } catch (e) {
          setIsLoading(false);
          console.error("error", e);
        }
      } catch (e) {
        console.error("error", e);
      }
    } else {
      toast.error("Transaction Error");
    }
  };
  return (
    <>
      <tr className="">
        <td className="px-4 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
          <div className="inline-flex items-center gap-x-3">
            <span>#{index + 1}</span>
          </div>
        </td>
        <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">
          {transaction?.inq_list?.users?.displayName}
        </td>
        <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
          <div className="inline-flex items-center px-3 py-1 rounded-full gap-x-2 text-emerald-500 bg-emerald-100/60 ">
            <h2 className="text-sm font-normal">
              {transaction?.acc_payment_method?.name}
            </h2>
          </div>
        </td>
        <Link
          to={`../inquiry/${transaction?.inq_list?.inquiry_no}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <td className="px-4 py-4 text-sm text-[#ff9b00]  whitespace-nowrap">
            {transaction?.inq_list?.inquiry_no}
          </td>
        </Link>
        <td className="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
          {transaction?.inq_list?.picked_user?.displayName}
        </td>
        <td className="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
          {moment(transaction?.created_at).format("YYYY-MM-DD")}
        </td>
        <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">
          {transaction?.ref_no}
        </td>
        <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">
          £{transaction?.amount}
        </td>

        <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">
          {transaction?.status ?? "pending"}
        </td>
        <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">
          <div className="flex items-center gap-2">
            <button
              type={"button"}
              onClick={() => setShowReceipt(true)}
              className="rounded py-1 px-3 mt-[7px]"
            >
              <ViewIcon />
            </button>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  sx={{
                    "& .MuiInputBase-root": {
                      "& .MuiInputBase-input": {
                        padding: "3px 8px",
                      },
                    },
                  }}
                  onChange={(date: any) => {
                    setDate(date?.$d);
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            {(transaction?.acc_payment_method?.card_terminal_type == "Manual" ||
              transaction?.acc_payment_method?.type === "bank") && (
              <input
                className="py-[3px] mt-[5px] px-2 min-w-[70px] ring-0 outline-0 hover:border-basic border border-gray-300 rounded "
                placeholder="Charges"
                value={charges}
                onChange={(e: any) => {
                  setCharges(e.target.value);
                }}
              />
            )}

            {transaction?.status !== "paid" && (
              <button
                type={"button"}
                onClick={() => setModelOpen(true)}
                className="bg-basic rounded py-1 px-3 text-white mt-[7px] ml-2"
              >
                {isLoading ? "Confirming.." : "Confirm Payment"}
              </button>
            )}
          </div>
        </td>
      </tr>

      {modalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          style={{ zIndex: 50 }}
        >
          <div className="relative bg-white rounded-xl shadow-2xl p-8 w-11/12 sm:w-3/4 md:w-1/2 lg:w-1/3">
            <h2 className="mb-6 text-2xl font-bold text-gray-700">
              Are you sure?
            </h2>
            <div className="flex justify-end space-x-4">
              <button
                onClick={(event: any) => setModelOpen(false)}
                className="px-5 py-2 font-semibold text-gray-700 bg-red-500 bg-opacity-10 rounded-full hover:bg-opacity-20"
              >
                No
              </button>
              <button
                onClick={(event: any) => execConfirm()}
                className="px-5 py-2 font-semibold text-white bg-green-500 rounded-full hover:bg-green-600"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      {showReceipt && (
        <div className="fixed z-[999] top-0 left-0 w-full min-h-screen bg-[#00000070] flex justify-center items-center py-[100px]">
          <div className="container bg-white rounded-[10px] p-[30px] max-w-[900px] m-[20px] w-full relative">
            <button
              onClick={() => setShowReceipt(false)}
              className="text-[16px] text-white flex justify-center items-center h-[25px] w-[25px] bg-red-500 rounded-full absolute right-[-10px] top-[-10px]"
            >
              x
            </button>
            <div className="">
              {transaction?.receipt ? (
                <img src={`${URLs.FILE_URL}${transaction?.receipt}`} />
              ) : (
                <p className="text-center">No file available</p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
