import useDefEmailTemplates from "~/hooks/useDefEmailTemplates";
import DefaultTemplates from "./components/DefaultTemplates";
import { useState } from "react";
import { Button, Modal, Box } from "@mui/material";
import AddTemplate from "./components/AddTemplates";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  outline: "3px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  gap: "30px",
};

const EmailTemplates = () => {
  const { defaultEmailTemplates } = useDefEmailTemplates();
  const [openTempMod, setOpenTempMod] = useState(false);

  return (
    <div className="text-primary dark:text-dark-primary">
      <div className="flex justify-between items-center">
        <h1>All Email Templates</h1>
        <Button variant="contained" onClick={() => setOpenTempMod(true)}>
          Add Template
        </Button>
      </div>
      {defaultEmailTemplates?.def_emails_templates?.map(
        (template: any, index: any) => (
          <DefaultTemplates key={template.id} template={template} />
        )
      )}
      <Modal open={openTempMod} onClose={() => setOpenTempMod(false)}>
        <Box sx={{ ...style, width: "70%" }}>
          <AddTemplate setOpenTempMod={setOpenTempMod} /> 
        </Box>
      </Modal>
    </div>
  );
};

export default EmailTemplates;
