import { Avatar, IconButton, Popover, createTheme } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { MdOutlineSearch, MdLightMode, MdApps } from "react-icons/md";
import { RxAvatar } from "react-icons/rx";
import { BsFillBellFill } from "react-icons/bs";
import { IoMenu } from "react-icons/io5";
import SideBar from "../sidebar/SideBar";
import Profile from "./components/Profile";
import Notification from "./components/Notification";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import Brightness4OutlinedIcon from "@mui/icons-material/Brightness4Outlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FormatAlignJustifyOutlinedIcon from "@mui/icons-material/FormatAlignJustifyOutlined";
import Whatsapp from "./Whatsapp";
import Notifications from "./Notifications";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import { useUserData, useUserDefaultRole, useUserId } from "@nhost/react";
import { GET_ASSIGNED_BRANDS, UNREAD_NOTIFICATOINS } from "./graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import NotificationsBox from "./Whatsapp/components/NotificationsBox";
import { Link, useNavigate } from "react-router-dom";
import { getTimeReadableFormat } from "~/utils/GetTimeReadable";
import { UPDATE_NOTIFICATION } from "./graphql/Mutation";
import Bell from "~/assets/svg/Bell";
import WhatsappSVG from "~/assets/svg/WhatsappSVG";
import Gmail from "./Gmail";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

function stringAvatar(name: any) {
  return {
    sx: {
      bgcolor: "#9155FD",
    },
    children: `${
      name.trim().split(" ").length > 1
        ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
        : `${name[0]}`
    }`,
  };
}

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [showEmailNotifications, setShowEmailNotifications] = useState(false);
  const [showNotificationBar, setShowNotificationBar] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const role = useUserDefaultRole();

  const userData = useUserData();

  const navigate = useNavigate();

  const storedDarkMode = localStorage.getItem("darkMode");

  const [darkMode, setDarkMode] = useState(
    storedDarkMode ? JSON.parse(storedDarkMode) : false
  );

  const root = document.documentElement;

  const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

  useEffect(() => {
    const handleSystemColorChange = (e: any) => {
      if (e.matches) {
        root.classList.add("dark");
        localStorage.setItem("darkMode", JSON.stringify(true));
        window.dispatchEvent(new Event("storage"));
      } else {
        root.classList.remove("dark");
        localStorage.setItem("darkMode", JSON.stringify(false));
        window.dispatchEvent(new Event("storage"));
      }
    };
    mediaQuery.addEventListener("change", handleSystemColorChange);
  }, []);

  useEffect(() => {
    if (
      !("theme" in localStorage) &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      if (storedDarkMode && JSON.parse(storedDarkMode) === false) {
        root.classList.remove("dark");
        setDarkMode(false);
        localStorage.setItem("darkMode", JSON.stringify(false));
        window.dispatchEvent(new Event("storage"));
      } else if (storedDarkMode && JSON.parse(storedDarkMode) === true) {
        root.classList.add("dark");
        setDarkMode(true);
        localStorage.setItem("darkMode", JSON.stringify(true));
        window.dispatchEvent(new Event("storage"));
      } else {
        root.classList.add("dark");
        setDarkMode(true);
        localStorage.setItem("darkMode", JSON.stringify(true));
        window.dispatchEvent(new Event("storage"));
      }
    } else {
      if (storedDarkMode && JSON.parse(storedDarkMode) === false) {
        root.classList.remove("dark");
        setDarkMode(false);
        localStorage.setItem("darkMode", JSON.stringify(false));
        window.dispatchEvent(new Event("storage"));
      } else if (storedDarkMode && JSON.parse(storedDarkMode) === true) {
        root.classList.add("dark");
        setDarkMode(true);
        localStorage.setItem("darkMode", JSON.stringify(true));
        window.dispatchEvent(new Event("storage"));
      } else {
        root.classList.remove("dark");
        setDarkMode(false);
        localStorage.setItem("darkMode", JSON.stringify(false));
        window.dispatchEvent(new Event("storage"));
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("darkMode", JSON.stringify(darkMode));
    window.dispatchEvent(new Event("storage"));
  }, [darkMode]);

  const { travelHouse }: any = travelHouseStore();
  const userID = useUserId();
  const { loading, data, error } = useQuery(GET_ASSIGNED_BRANDS, {
    variables: { id: userID, thp_id: travelHouse?.id },
  });

  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const brdRec: any = [];
    const brdPhone: any = [];
    if (data) {
      if (data?.brd_users?.length > 0) {
        data?.brd_users?.map((brd: any) => {
          brdRec.push({
            id: brd?.brd_id,
            name: brd.brd_list.name,
            phone: brd.brd_list?.phoneNumberId,
            phoneNumberId: brd.brd_list?.phoneNumberId,
            whatsapp_token: brd.brd_list?.whatsapp_token,
          });
        });
      } else {
        data?.thp_list?.[0]?.brd_lists?.map((brd: any) => {
          brdRec.push({
            id: brd?.id,
            name: brd.name,
            phone: brd.brd_list?.phoneNumberId,
            phoneNumberId: brd.brd_list?.phoneNumberId,
            whatsapp_token: brd.brd_list?.whatsapp_token,
          });
        });
      }
      setBrands(brdRec);
    }
  }, [loading]);

  const brd_ids: any = [];
  const brand_phone: any = [];

  brands.map((brd: any) => {
    brd_ids.push(brd.id);
    if (brd?.phone) brand_phone.push(brd?.phone);
  });

  const [updateNoti] = useMutation(UPDATE_NOTIFICATION);

  const markAsRread = async (noti_id: any) => {
    const resSuggestion = await updateNoti({
      variables: { id: noti_id, data: { read: true } },
    });
  };

  const [notificationDropdown, setNotificationDropdown]: any = useState(false);

  const userId: any = useUserId();

  const {
    loading: countLoading,
    data: countData,
    error: countError,
  } = useSubscription(UNREAD_NOTIFICATOINS, {
    variables: { user_id: userId, brd_ids: brd_ids },
  });

  return (
    <>
      <div className="w-[100%] min-h-[64px] text-primary bg-primary dark:bg-dark-primary  flex items-center justify-between px-4">
        <div className="flex items-center">
          <div className="lg:hidden flex">
            <IconButton
              onClick={() => {
                if (showUserDetails) {
                  setShowMenu(!showMenu);
                  setShowUserDetails(!showUserDetails);
                } else if (showNotificationBar) {
                  setShowNotificationBar(!showNotificationBar);
                  setShowMenu(!showMenu);
                } else {
                  setShowMenu(!showMenu);
                }
              }}
            >
              <FormatAlignJustifyOutlinedIcon />
            </IconButton>
            <div
              className={`absolute top-0 z-20 transition-all ease-in-out duration-500 ${
                showMenu ? " left-0" : " left-[-100%]"
              }`}
            >
              <SideBar showMenu={showMenu} setShowMenu={setShowMenu} />
            </div>
            {showMenu && (
              <div
                onClick={() => setShowMenu(!showMenu)}
                className="absolute top-0 left-0 w-full h-full bg-black/50 z-10 transition-all ease-in-out duration-500"
              ></div>
            )}
          </div>
          <IconButton>
            <SearchOutlinedIcon />
          </IconButton>
          <h1 className="text-[#3A354161] dark:text-dark-secondary  ml-4 cursor-pointer lg:flex hidden">
            Search (Ctrl + /)
          </h1>
        </div>
        <div className="flex items-center">
          <Gmail />
          <IconButton
            onClick={() => {
              if (!(root.className === "dark")) {
                root.classList.add("dark");
                setDarkMode(true);
              } else {
                root.classList.remove("dark");
                setDarkMode(false);
              }
            }}
          >
            <Brightness4OutlinedIcon />
          </IconButton>
          <IconButton>
            <AppsOutlinedIcon />
          </IconButton>

          {/* <IconButton
            className="mx-4"
            onClick={(e: any) => {
              if (showMenu) {
                setShowMenu(!showMenu);
                setShowNotificationBar(!showNotificationBar);
                setShowEmailNotifications(!showEmailNotifications);
              } else if (showUserDetails) {
                setShowUserDetails(!showUserDetails);
                setShowNotificationBar(!showNotificationBar);
                setShowEmailNotifications(!showEmailNotifications);
              } else if (showNotificationBar) {
                setShowNotificationBar(!showNotificationBar);
                setShowUserDetails(!showUserDetails);
                setShowEmailNotifications(!showEmailNotifications);
              } else {
                setShowEmailNotifications(!showEmailNotifications);
              }
              setAnchorEl(e.currentTarget);
            }}
          >
            <Popover
              sx={{
                background: "transparent",
                top: "5px",
                right: "3px",
              }}
              anchorEl={anchorEl}
              open={showEmailNotifications}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {brands.length > 0 && brand_phone.length > 0 && (
                <Notification
                  brand_phone={brand_phone}
                  brands={brands[0]}
                  type="email"
                />
              )}

              <Notification brand_phone={brand_phone} brands={brands[0]} />
            </Popover>
          </IconButton> */}

          <IconButton
            onClick={() => {
              navigate(`/${role}/emails`);
            }}
          >
            <EmailOutlinedIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              navigate(`/${role}/whatsapp`);
            }}
          >
            <WhatsappSVG />
          </IconButton>

          {/* <IconButton
            className="mx-4"
            onClick={(e: any) => {
              if (showMenu) {
                setShowMenu(!showMenu);
                setShowNotificationBar(!showNotificationBar);
                setShowEmailNotifications(!showEmailNotifications);
              } else if (showUserDetails) {
                setShowUserDetails(!showUserDetails);
                setShowNotificationBar(!showNotificationBar);
                setShowEmailNotifications(!showEmailNotifications);
              } else if (showEmailNotifications) {
                setShowNotificationBar(!showNotificationBar);
                setShowUserDetails(!showUserDetails);
                setShowEmailNotifications(!showEmailNotifications);
              } else {
                setShowNotificationBar(!showNotificationBar);
              }
              setAnchorEl(e.currentTarget);
            }}
          >
            <Popover
              sx={{
                background: "transparent",
                top: "5px",
                right: "3px",
              }}
              anchorEl={anchorEl}
              open={showNotificationBar}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {brands.length > 0 && brand_phone.length > 0 && (
                <Notification brand_phone={brand_phone} brands={brands[0]} />
              )}

              <Notification brand_phone={brand_phone} brands={brands[0]} />
            </Popover>
          </IconButton> */}

          {/* <Notifications brd_ids={brd_ids} /> */}

          <IconButton
            onClick={(e: any) => {
              if (showMenu) {
                setShowMenu(!showMenu);
                setShowUserDetails(!showUserDetails);
                setShowEmailNotifications(!showEmailNotifications);
              } else if (showNotificationBar) {
                setShowNotificationBar(!showNotificationBar);
                setShowUserDetails(!showUserDetails);
                setShowEmailNotifications(!showEmailNotifications);
              } else if (showEmailNotifications) {
                setShowNotificationBar(!showNotificationBar);
                setShowUserDetails(!showUserDetails);
                setShowEmailNotifications(!showEmailNotifications);
              } else {
                setShowUserDetails(!showUserDetails);
              }
              setAnchorEl(e.currentTarget);
            }}
          >
            <Avatar
              style={{
                fontSize: "17px",
                fontWeight: "bolder",
                width: "30px",
                height: "30px",
              }}
              {...stringAvatar(userData?.displayName?.toUpperCase())}
            />
            {/* <AccountCircleIcon className="text-basic" fontSize="large" /> */}
            <Popover
              sx={{
                background: "transparent",
                top: "5px",
                right: "3px",
              }}
              anchorEl={anchorEl}
              open={showUserDetails}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Profile userData={userData} stringAvatar={stringAvatar} />
            </Popover>
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default Header;
