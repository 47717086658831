import { Box, Button, Divider, TextField } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-toastify";
import { getPaidAmount } from "~/modules/InquiriesModule/utils/getInquiryStats";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";
import {
  ADD_TRANSACTION,
  COMPLETE_INQ,
  EDIT_INQUIRY,
} from "../CompleteInquiry/graphql/Mutation";
import { useApolloClient, useMutation } from "@apollo/client";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const RefundInquiry = ({ inquiry, setSidePopup }: any) => {
  const [loading, setLoading] = useState<any>(false);
  const [venderRefund, setVenderRefund] = useState<any>();
  const [customerRefund, setCustomerRefund] = useState<any>();

  const [date, setDate] = useState<any>(new Date());

  const [editInquiry] = useMutation(COMPLETE_INQ);
  const [addTransaction] = useMutation(ADD_TRANSACTION);

  const runRefundProcess = async () => {
    const transactionNo1 = generateRandom10DigitCode();
    const transactionNo2 = generateRandom10DigitCode();
    setLoading(true);

    const transactions: any = [
      {
        brd_id: inquiry?.brd_id,
        def_acc_id: 5,
        inq_id: inquiry?.id,
        transactions_no: transactionNo2,
        date: moment(date).format("YYYY-MM-DD"),
        created_at: moment(date).format("YYYY-MM-DD"),
        type: "debit",
        amount: getPaidAmount(inquiry),
      },
      {
        brd_id: inquiry?.brd_id,
        def_acc_id: 16,
        inq_id: inquiry?.id,
        transactions_no: transactionNo2,
        date: moment(date).format("YYYY-MM-DD"),
        created_at: moment(date).format("YYYY-MM-DD"),
        type: "credit",
        amount: -(getPaidAmount(inquiry) - parseFloat(customerRefund)),
      },
      {
        brd_id: inquiry?.brd_id,
        def_acc_id: 4,
        inq_id: inquiry?.id,
        transactions_no: transactionNo2,
        user_id: inquiry?.users.id,
        date: moment(date).format("YYYY-MM-DD"),
        created_at: moment(date).format("YYYY-MM-DD"),
        type: "credit",
        note: "Refund",
        amount: -parseFloat(customerRefund),
      },
      {
        brd_id: inquiry?.brd_id,
        def_acc_id: 7,
        inq_id: inquiry?.id,
        transactions_no: transactionNo1,
        date: moment(date).format("YYYY-MM-DD"),
        created_at: moment(date).format("YYYY-MM-DD"),
        type: "credit",
        amount: -parseFloat(
          inquiry?.selected_suggestion?.inq_suggestion_costs?.reduce?.(
            (acc: any, item: any) => acc + item.atol_price,
            0
          )
        ),
      },
      {
        brd_id: inquiry?.brd_id,
        def_acc_id: 6,
        inq_id: inquiry?.id,
        transactions_no: transactionNo1,
        date: moment(date).format("YYYY-MM-DD"),
        created_at: moment(date).format("YYYY-MM-DD"),
        type: "credit",
        amount: -parseFloat(
          inquiry?.selected_suggestion?.inq_suggestion_costs?.reduce?.(
            (acc: any, item: any) => acc + item.tax_price,
            0
          ) +
            inquiry?.selected_suggestion?.inq_suggestion_costs?.reduce?.(
              (acc: any, item: any) => acc + item.basic_fare,
              0
            )
        ),
      },
      {
        brd_id: inquiry?.brd_id,
        def_acc_id: 2,
        inq_id: inquiry?.id,
        transactions_no: transactionNo1,
        user_id: "51923753-c314-4597-a923-2ddf76c51cff",
        date: moment(date).format("YYYY-MM-DD"),
        created_at: moment(date).format("YYYY-MM-DD"),
        type: "debit",
        note: "Refund",
        amount: parseFloat(venderRefund),
      },
      {
        brd_id: inquiry?.brd_id,
        inq_id: inquiry?.id,
        def_acc_id: 15,
        transactions_no: transactionNo1,
        date: moment(date).format("YYYY-MM-DD"),
        created_at: moment(date).format("YYYY-MM-DD"),
        type: "debit",
        amount: parseFloat(
          (
            parseFloat(
              inquiry?.selected_suggestion?.inq_suggestion_costs?.reduce?.(
                (acc: any, item: any) => acc + item.tax_price,
                0
              ) +
                inquiry?.selected_suggestion?.inq_suggestion_costs?.reduce?.(
                  (acc: any, item: any) => acc + item.basic_fare,
                  0
                ) +
                inquiry?.selected_suggestion?.inq_suggestion_costs?.reduce?.(
                  (acc: any, item: any) => acc + item.atol_price,
                  0
                )
            ) - parseFloat(venderRefund)
          ).toFixed(2)
        ),
      },
    ];

    if (
      transactions[2].amount +
        transactions[1].amount +
        transactions[0].amount ===
        0 &&
      transactions[6].amount +
        transactions[5].amount +
        transactions[4].amount +
        transactions[3].amount ===
        0
    ) {
      for (let i = 0; i < transactions.length; i++) {
        await addTransaction({
          variables: {
            transactions: [transactions[i]],
          },
        });
      }

      // await Promise.all(
      //   transactions.map(async (transaction: any) => {
      //     try {
      //       const res = await addTransaction({
      //         variables: {
      //           transactions: [transaction],
      //         },
      //       });
      //     } catch (error) {}
      //   })
      // ).then(async () => {
      //   toast.success("Transactions Added Successfully");
      //   await client.resetStore();
      // });

      const response = await editInquiry({
        variables: {
          id: inquiry.id,
          status: "refunded",
          completedAt: moment(date).format("YYYY-MM-DD"),
        },
      });

      if (response?.data?.update_inq_list?.returning?.length > 0) {
        toast.success("Inquiry Refunded Successfully");
        setLoading(false);
        setSidePopup(false);
      }
    } else {
      toast.error("Transaction Error");
      setLoading(false);
    }
  };

  return (
    <>
      <div className="min-w-[500px] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-3xl text-basic dark:text-dark-primary">Refund information</h1>
        <Divider />
        <Box>
          <div className="grid grid-cols-1 gap-5 mt-10">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  defaultValue={dayjs(new Date())}
                  onChange={(date: any) => {
                    setDate(date?.$d);
                  }}
                  sx={{ width: "100%" }}
                />
              </DemoContainer>
            </LocalizationProvider>
            <TextField
              onChange={(e: any) => setVenderRefund(e.target.value)}
              value={venderRefund}
              label="Refund from Vendor"
            />
            <TextField
              label="Refund to Customer"
              value={customerRefund}
              onChange={(e: any) => setCustomerRefund(e.target.value)}
            />
            <Button onClick={runRefundProcess} variant="contained">
              {loading ? "Refund Processing..." : "Refund Process"}
            </Button>
          </div>
        </Box>
      </div>
    </>
  );
};

export default RefundInquiry;
