import React, { useEffect } from "react";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
// import gql queries
import { GET_TRAVEL_HOUSE } from "./graphql/Query";
import travelHouseStore from "../../../../store/travelHouse/travelHouseStore";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import NoTravelHouse from "./components/NoTravelHouse";
import { URLs } from "~/config/enums";
import { GetCustomUrl } from "~/utils/GetCustomUrl";
import { INSERT_FINGERPRINT_IDS } from "~/graphql/user_fingerprint_ids/Mutation";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { INSERT_THP_USER_ATTENDANCE } from "~/graphql/thp_user_attendance/Mutation";
import { GET_THP_USER_ATTENDANCE } from "~/graphql/thp_user_attendance/Query";
import moment from "moment";

export default function VerifyUser() {
  const navigate = useNavigate();
  const userDefaultRole = useUserDefaultRole();
  const userId = useUserId();
  const { setTravelHouse }: any = travelHouseStore();
  const client = useApolloClient();

  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  const customUrl = GetCustomUrl(window.location.host);
  // get data based on query
  const { loading, data, error } = useQuery(GET_TRAVEL_HOUSE, {
    variables: { custom_url: customUrl, user_id: userId },
  });

  const [addUserFingerPrintId] = useMutation(INSERT_FINGERPRINT_IDS);
  const [addUserAttendence] = useMutation(INSERT_THP_USER_ATTENDANCE);

  const fpPromise = FingerprintJS.load();

  // useEffect(() => {
  //   if (userId) {
  //     fpPromise
  //       .then((fp) => fp.get())
  //       .then(async (result) => {
  //         const visitorId = result.visitorId;
  //         await addUserFingerPrintId({
  //           variables: { fingerprintID: visitorId, userID: userId },
  //         });
  //       })
  //       .catch(() => {});

  //     const runFunc = async () => {
  //       const userAtt = client.query({
  //         query: GET_THP_USER_ATTENDANCE,
  //         variables: {
  //           userId: userId,
  //           attendanceDate: moment().format("YYYY-MM-DD"),
  //         },
  //       });
  //       console.log("daaata", userAtt);
  //       if (userAtt?.data?.thp_user_attendance) {
  //         const user = window.confirm("checking?");
  //       }
  //       const user = window.confirm(
  //         "Do you want to mark your attendance or not?"
  //       );
  //       if (user) {
  //         try {
  //           await addUserAttendence({
  //             variables: {
  //               userID: userId,
  //             },
  //           });
  //         } catch (error) {}
  //       }
  //     };
  //     runFunc();
  //   }
  // }, [userId]);

  useEffect(() => {
    if (userId) {
      fpPromise
        .then((fp) => fp.get())
        .then(async (result) => {
          const visitorId = result.visitorId;
          await addUserFingerPrintId({
            variables: { fingerprintID: visitorId, userID: userId },
          });
        })
        .catch(() => {});

      const runFunc = async () => {
        try {
          // Await the result of the query
          const { data } = await client.query({
            query: GET_THP_USER_ATTENDANCE,
            variables: {
              userId: userId,
              attendanceDate: moment().format("YYYY-MM-DD"),
            },
          });
          // if (!data?.thp_user_attendance?.[0]) {
          //   const user = window.confirm("Do you want to CheckIn?");
          //   if (user) {
          //     try {
          //       await addUserAttendence({
          //         variables: {
          //           userID: userId,
          //         },
          //       });
          //     } catch (error) {
          //       console.error("Error marking attendance:", error);
          //     }
          //   }
          // }
          if (!data?.thp_user_attendance?.[0]) {
            try {
              await addUserAttendence({
                variables: {
                  userID: userId,
                },
              });
            } catch (error) {
              console.error("Error marking attendance:", error);
            }
          }
        } catch (error) {
          console.error("Error fetching user attendance:", error);
        }
      };

      runFunc();
    }
  }, [userId]);

  // return loading while loading data
  if (loading) return <div>Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query verify {error.message}</div>;

  if (data.thp_list.length !== 0) {
    setTravelHouse(data.thp_list[0]);
    navigate(from);
  } else {
    if (userDefaultRole === "super-admin") {
      navigate(from);
    }
  }

  return (
    <div className="">
      {data.thp_list.length !== 0 && (
        <h2>
          Travel House Assigned, But something went wrong with you! {":("}
        </h2>
      )}
      {data.thp_list.length === 0 && <NoTravelHouse />}
    </div>
  );
}
