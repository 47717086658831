import { gql } from "@apollo/client";

export const ADD_CONV_LABELS = gql`
  mutation MyMutation($data: [brd_whatsapp_Conv_label_insert_input!]!) {
    insert_brd_whatsapp_Conv_label(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
