import { gql } from "@apollo/client";

export const GET_CONVERSATIONS = gql`
  subscription GetConversation(
    $condition: brd_whatsapp_conversations_bool_exp!
    $offset: Int!
  ) {
    brd_whatsapp_conversations(
      where: $condition
      order_by: { last_message_time: desc }
      limit: 10
      offset: $offset
    ) {
      id
      user_phone
      brand_phone
      whatsapp_name
      last_view_time
      created_at
      updated_at
      user_id
      last_message
      last_message_time
      assigned_consultant
      consultant_detail {
        displayName
      }
      whatsapp_labels {
        label_id
        label_detail {
          name
        }
      }
      brd_whatsapp_messages {
        sender {
          displayName
        }
        sender_role
        brd_whatsapp_messages_statuses {
          id
          key
        }
      }
      brd_list {
        id
        name
        brd_details {
          show_consultant_whatsapp_conv
        }
      }
    }
  }
`;

export const GET_QUERY_CONVERSATIONS = gql`
  query GetConversation(
    $condition: brd_whatsapp_conversations_bool_exp!
    $offset: Int!
  ) {
    brd_whatsapp_conversations(
      where: $condition
      order_by: { last_message_time: desc }
      limit: 10
      offset: $offset
    ) {
      id
      user_phone
      brand_phone
      whatsapp_name
      last_view_time
      created_at
      updated_at
      user_id
      last_message
      last_message_time
      assigned_consultant
      consultant_detail {
        displayName
      }
      whatsapp_labels {
        label_id
        label_detail {
          name
        }
      }
      brd_whatsapp_messages {
        sender {
          displayName
        }
        sender_role
        brd_whatsapp_messages_statuses {
          id
          key
        }
      }
      brd_list {
        id
        name
        brd_details {
          show_consultant_whatsapp_conv
        }
      }
    }
  }
`;
