import React from "react";
import { useUserData } from "@nhost/react";
import UserCard from "~/components/UserCard";

export default function Profile() {
  const userData = useUserData();
  return (
    <>
      <h1 className="text-primary dark:text-dark-primary uppercase text-[18px] font-bold border-b-2 divide-gray-200  dark:divide-gray-700 mb-2">
        Profile
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
        <UserCard key={1} user={userData} editAble={false} />
      </div>
    </>
  );
}
