import React from "react";
import SidePopup from "~/components/common/SidePopup";
import AccountDetail from "./components/AccountDetail";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { GET_ACCOUNTS, GET_DEFAULT_ACCOUNTS } from "./graphql/Query";
import { useQuery } from "@apollo/client";
import AddAccount from "./components/AddAccount/AddAccout";

export default function AccountsList() {
  const { travelHouse }: any = travelHouseStore();

  const {
    loading: def_loading,
    data: def_data,
    error: def_error,
  } = useQuery(GET_DEFAULT_ACCOUNTS, {
    variables: { thp_id: travelHouse.id },
  });

  const { loading, data, error } = useQuery(GET_ACCOUNTS, {
    variables: { thp_id: travelHouse.id },
  });

  if (def_loading) return <div>Loading...</div>;
  if (def_error) return <div>Error in the query {def_error.message}</div>;

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <>
      <h1 className="text-primary dark:text-dark-primary  uppercase text-[18px] font-bold border-b-2 divide-gray-200  dark:divide-gray-700 mb-2">
        Accounts List
      </h1>
      <AccountDetail accounts={[...def_data.def_acc_list, ...data.acc_list]} />
      <SidePopup component={<AddAccount />} />
      {/* <SidePopup component={<EditAccount />} text="Add Account" /> */}
    </>
  );
}
