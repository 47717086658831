import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Avatar,
  Button,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { GET_SOCIAL_AUTHENTICATION_INFO } from "~/graphql/brd_social_authentication/Query";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { DELETE_BRD_SOCIAL_AUTHENTICATION } from "~/graphql/brd_social_authentication/Mutation";

const SocialAccountsTable = () => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const { brd_id } = useParams<{ brd_id: string }>(); // Explicitly type brd_id
  const { data, refetch } = useQuery(GET_SOCIAL_AUTHENTICATION_INFO, {
    variables: { brd_id },
  });

  // Extract social accounts or set an empty array if none found
  const socialAccounts = data?.brd_social_authentication || [];

  // Set up the delete mutation
  const [deleteAuthentication] = useMutation(DELETE_BRD_SOCIAL_AUTHENTICATION);

  // Function to handle deleting a specific social account
  const handleDelete = async (profile_id: string, brd_id: string) => {
    try {
      const response = await deleteAuthentication({
        variables: {
          profile_id, // Pass the specific profile_id
          brd_id, // Pass the specific brd_id
        },
      });

      if (response.data.delete_brd_social_authentication.affected_rows > 0) {
        console.log("Delete successful");
        refetch(); // Refetch the list after deletion
      }
    } catch (error) {
      console.error("Error while deleting:", error);
    }
  };

  return (
    <>
      <TableContainer
        component={Paper}
        className="bg-secondary dark:bg-dark-secondary"
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="text-primary dark:text-dark-primary">
                Profile
              </TableCell>
              <TableCell className="text-primary dark:text-dark-primary">
                Status
              </TableCell>
              <TableCell className="text-primary dark:text-dark-primary">
                Token Type
              </TableCell>
              <TableCell className="text-primary dark:text-dark-primary">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {socialAccounts.length > 0 ? (
              socialAccounts.map((account: any, index: any) => (
                <TableRow key={index}>
                  <TableCell>
                    <div className="flex items-center">
                      <Avatar
                        src={account.profile_picture}
                        alt="Static User"
                        className="mr-3"
                      />
                      <div>
                        <h3 className="text-lg font-semibold">
                          {account.user_name}
                        </h3>
                        <p className="text-sm text-gray-600 text-primary dark:text-dark-primary">
                          {account.platform}
                        </p>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>Connected</TableCell>
                  <TableCell>{account.accessToken_type}</TableCell>

                  <TableCell>
                    <div className="flex gap-2">
                      {/* <Button
                        variant="outlined"
                        onClick={() => setOpen(account.access_token)}
                      >
                        Connect Page
                      </Button> */}
                      <Button
                        variant="outlined"
                        onClick={() => {
                          if (brd_id) {
                            handleDelete(account.profile_id, brd_id);
                          } else {
                            console.error("brd_id is undefined");
                          }
                        }}
                      >
                        Disconnect
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} className="text-center">
                  No social accounts connected
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog */}
      {/* <OrganizationSelection open={open} onClose={handleClose} /> */}
    </>
  );
};

export default SocialAccountsTable;
