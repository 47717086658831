import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useApolloClient, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
// Import compoonents
import FlightType from "./components/FlightType";
import FlightPrices from "./components/FlightPrices";
import FlightLeg from "./components/FlightLeg";
// Import graphql
import {
  ADD_SUGGESTION_COST,
  EDIT_SUGGESTION,
  EDIT_SUGGESTION_COST,
  EDIT_SUGGESTION_STOP,
  EDIT_SUGGESTION_LEG,
} from "./graphql/Mutation";
// Import utils
import FlightDates from "./components/FlightDates";
import useNotifications from "hooks/useNotifications";
import PassengersCost from "./components/PassengersCost";
import { formatData } from "../../../../../AddSuggestion/utils/formatDataEdit";
import moment from "moment";
import { useUserDisplayName, useUserId } from "@nhost/react";
import useEmail from "~/hooks/emails/useEmail";
import { UPDATE_INQ_UPDATE_USER } from "~/graphql/inq_list/Mutation";

export default function EditSuggestionForm({
  defaultValue,
  inquiry_id,
  inquiry,
  suggestion,
  user_id,
  passengers,
  suggestion_cost,
  setShowAddModal,
}: any) {
  const [editSuggestion] = useMutation(EDIT_SUGGESTION);
  const [editSuggestionStop] = useMutation(EDIT_SUGGESTION_STOP);
  const [editSuggestionLeg] = useMutation(EDIT_SUGGESTION_LEG);
  const [editSuggestionCost] = useMutation(EDIT_SUGGESTION_COST);
  const [addSuggestionCost] = useMutation(ADD_SUGGESTION_COST);
  const userName = useUserDisplayName();
  const emailSend = useEmail();

  const userId = useUserId();

  const [updateInqListUser] = useMutation(UPDATE_INQ_UPDATE_USER);

  const client = useApolloClient();
  //button loading state
  const [isLoading, setIsloading] = useState<any>(false);
  // Define react hooks form
  const alert = useNotifications();
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
    setValue,
    getValues,
    unregister,
  } = useForm({
    defaultValues: defaultValue,
  });

  // Submit form function
  const onSubmit = async (formData: any) => {
    // ;
    // ;

    setIsloading(true);
    if (!isLoading) {
      formData["inquiry_id"] = inquiry_id; // add inquiry id to formdata

      // EDIT SUGGESTION
      const inq_suggestion: any = {};
      inq_suggestion["fare_expiry"] = moment(formData.fareExpiry).format(
        "YYYY-MM-DD"
      );
      inq_suggestion["due_date"] = moment(formData.invoiceDueDate).format(
        "YYYY-MM-DD"
      );

      inq_suggestion["pnr"] = formData.pnr;
      inq_suggestion["bookingFee"] = formData.bookingFee;

      await updateInqListUser({
        variables: { id: inquiry.id, updated_by: userId },
      });

      const resSuggestion = await editSuggestion({
        variables: { id: formData.id, suggestion: inq_suggestion },
      });

      // Add Suggestion Cost
      let suggestion_cost_add: any = [];
      if (formData.suggestion_cost_add) {
        if (formData.suggestion_cost_add.length > 0) {
          formData.suggestion_cost_add.map((sca: any) => {
            sca["suggestion_id"] = formData.id;
            suggestion_cost_add.push(sca);
          });
          const resSuggestionCostAdd = await addSuggestionCost({
            variables: { suggestion_cost: suggestion_cost_add },
          });
        }
      } else {
      }

      // EDIT SUGGESTION COST
      formData.suggestion_cost.map(async (cost: any) => {
        const suggestionCost = { ...cost };
        const id = suggestionCost["id"];
        delete suggestionCost["id"];
        delete suggestionCost["passengers_detail"];
        delete suggestionCost["suggestion_id"];

        const resSuggestionCost = await editSuggestionCost({
          variables: { id: id, suggestion_cost: suggestionCost },
        });
      });

      // Array.from({ length: formData.tripType }, (item: any, index: any) =>
      //   index === 0 ? "departLeg" : "returnLeg"
      // ).forEach(async (legType: any, index: any) => {
      //   const inq_suggestion_leg: any = {};
      //   inq_suggestion_leg["hand_carry"] = formData[legType]?.handCary;
      //   inq_suggestion_leg["luggage"] = formData[legType]?.luggage;
      //   const inq_suggestion_leg_id = formData[legType]?.id;
      //   inq_suggestion_leg["cabin"] = formData?.cabinType;
      //   // Edit Suggestion Leg
      //   console.log(
      //     "inq_suggestion_leg",
      //     inq_suggestion_leg,
      //     inq_suggestion_leg_id
      //   );
      //   const resSuggestionLeg = await editSuggestionLeg({
      //     variables: {
      //       id: inq_suggestion_leg_id,
      //       inq_suggestion_leg: inq_suggestion_leg,
      //     },
      //   });
      //   // Edit Suggestion Leg Stop
      //   formData[legType]?.stops.forEach((stop: any, ind: any) => {
      //     let inq_suggestion_leg_stops_id = stop.id;
      //     let inq_suggestion_leg_stops: any = {};
      //     inq_suggestion_leg_stops["depart"] = stop.departCity.iata_code;
      //     inq_suggestion_leg_stops["arrival"] = stop.arrivalCity.iata_code;
      //     inq_suggestion_leg_stops["ordering"] = ind + 1;
      //     inq_suggestion_leg_stops["depart_datetime"] = stop.departDateTime;
      //     inq_suggestion_leg_stops["arrival_datetime"] = stop.arrivalDateTime;
      //     inq_suggestion_leg_stops["airline_iata"] = stop.airline.iata;
      //     inq_suggestion_leg_stops["flight_no"] = stop.airlineNumber;

      //     const updateSugStopsFunc = async () => {
      //       await editSuggestionStop({
      //         variables: {
      //           id: inq_suggestion_leg_stops_id,
      //           inq_suggestion_leg_stops: inq_suggestion_leg_stops,
      //         },
      //       });
      //       inq_suggestion_leg_stops_id = stop.id;
      //       inq_suggestion_leg_stops = {};
      //     };
      //     updateSugStopsFunc();
      //   });

      const legTypes = Array.from(
        { length: formData.tripType },
        (item, index) => (index === 0 ? "departLeg" : "returnLeg")
      );

      for (const legType of legTypes) {
        const inq_suggestion_leg: any = {};
        inq_suggestion_leg["hand_carry"] = formData[legType]?.handCary;
        inq_suggestion_leg["luggage"] = formData[legType]?.luggage;
        const inq_suggestion_leg_id = formData[legType]?.id;
        inq_suggestion_leg["cabin"] = formData?.cabinType;

        // Edit Suggestion Leg
        console.log(
          "inq_suggestion_leg",
          inq_suggestion_leg,
          inq_suggestion_leg_id,
          legTypes
        );

        const resSuggestionLeg = await editSuggestionLeg({
          variables: {
            id: inq_suggestion_leg_id,
            inq_suggestion_leg: inq_suggestion_leg,
          },
        });

        // Edit Suggestion Leg Stops
        const stops = formData[legType]?.stops || [];
        for (let ind = 0; ind < stops.length; ind++) {
          const stop = stops[ind];
          const inq_suggestion_leg_stops_id = stop.id;
          const inq_suggestion_leg_stops = {
            depart: stop.departCity.iata_code,
            arrival: stop.arrivalCity.iata_code,
            ordering: ind + 1,
            depart_datetime: moment(stop.departDateTime).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            arrival_datetime: moment(stop.arrivalDateTime).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            airline_iata: stop.airline.iata,
            flight_no: stop.airlineNumber,
          };

          await editSuggestionStop({
            variables: {
              id: inq_suggestion_leg_stops_id,
              inq_suggestion_leg_stops: inq_suggestion_leg_stops,
            },
          });
        }
      }

      // formatData(formData).map(async (sg: any, index: any) => {
      //   const suggestion: any = { ...sg };
      //   const suggestion_id = suggestion.id;
      //   delete suggestion["id"];
      //   const stops: any = suggestion["suggestion_stops"];
      //   const suggestion_cost: any = suggestion["suggestion_cost"];
      //   delete suggestion["suggestion_stops"];
      //   delete suggestion["suggestion_cost"];
      //   delete suggestion["suggestion_cost_add"];

      //   const resSuggestionLeg = await editSuggestionLeg({
      //     variables: { id: suggestion_id, suggestion_stop: suggestion_cost },
      //   });
      //   await client.refetchQueries({
      //     include: "all", // Consider using "active" instead!
      //   });

      //   stops.data.map(async (st: any) => {
      //     const stop: any = { ...st };
      //     const stop_id = stop?.id;
      //     delete stop["id"];
      //     const resSuggestionStop = await editSuggestionStop({
      //       variables: { id: stop_id, suggestion_stop: stop },
      //     });
      //     await client.refetchQueries({
      //       include: "all", // Consider using "active" instead!
      //     });
      //   });
      // });

      await client.resetStore();

      const variables: any = {
        inquiryNo: inquiry?.inquiry_no,
        brandName: inquiry?.brd_list?.name,
        userFirstName: inquiry?.users?.displayName,
        userLastName: "",
        consultantName: userName,
      };

      // ;
      await emailSend(
        4,
        inquiry.brd_id,
        inquiry.users?.id,
        inquiry.users?.email,
        inquiry.users?.phoneNumber,
        variables
      );

      toast.success(`Suggestions edit successfully`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      await alert.newNotification(
        user_id,
        `Your consultant updated a suggestion Please review in the portal `,
        "",
        ``
      );

      setIsloading(false);
      setShowAddModal(false); // hide form modal
    }
    setIsloading(false);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Flight types trip_type and cabin classes */}
      <FlightType
        errors={errors}
        register={register}
        control={control}
        setValue={setValue}
        unregister={unregister}
        getValues={getValues}
      />
      <hr className="border-gray-300  border-dashed my-4" />

      {/* Map flight legs based on trip type */}
      {suggestion.inq_suggestion_legs.map((item: any, index: any) => {
        return (
          <>
            {/* flight Data component */}
            <FlightLeg
              key={index}
              leg={index === 0 ? "departLeg" : "returnLeg"}
              errors={errors}
              register={register}
              setValue={setValue}
              control={control}
              unregister={unregister}
              getValues={getValues}
            />
            <hr className="border-gray-300  border-dashed my-4" />
          </>
        );
      })}

      {/* Dates section */}
      <FlightDates
        getValues={getValues}
        setValue={setValue}
        control={control}
        errors={errors}
        register={register}
      />

      {/* Flight Prices section */}
      <PassengersCost
        passengers={passengers}
        suggestion_cost={suggestion_cost}
        errors={errors}
        register={register}
        control={control}
        setValue={setValue}
        watch={watch}
      />
      {/* Submit form button */}
      <button
        className={` ${
          isLoading ? "bg-basic cursor-wait" : "bg-basic"
        }  h-[50px] p-2 w-half px-4 rounded-lg text-white mt-2`}
        type="submit"
      >
        {isLoading ? "Updating.." : "Update Suggestion"}
      </button>
    </form>
  );
}
