import moment from "moment";
import React, { useEffect, useState } from "react";

const CountDown = ({ endDate }: any) => {
  const newEndDate = moment(endDate);
  const [countdown, setCountdown] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment();
      const diff = moment.duration(newEndDate.diff(now));

      if (diff.asMilliseconds() <= 0) {
        clearInterval(interval);
        setCountdown("Expired");
        return;
      }

      const days = Math.floor(diff.asDays());
      const hours = diff.hours();
      const minutes = diff.minutes();
      const seconds = diff.seconds();

      const countdownString = `${days}:${hours}:${minutes}:${seconds}`;
      setCountdown(countdownString);
    }, 1000);

    return () => clearInterval(interval);
  }, [endDate]);

  if(endDate){
  return <div><span className={countdown === "Expired"  ? `text-[red]` : ``}>{countdown}</span></div>}else{
    return<></>
  }
};

export default CountDown;
