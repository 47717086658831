import { useEffect, useState } from "react";
import Papa from "papaparse";
import { Button, LinearProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { toast } from "react-toastify";
import { useApolloClient, useMutation } from "@apollo/client";
import { GET_EMAIL_CAMP_CONVERSATION } from "~/graphql/brd_email_campaigns/Query";
import {
  INSERT_EMAIL_CAMP_CONVERSATION,
  UPDATE_EMAIL_CAMP_CONVERSATION,
} from "~/graphql/brd_email_campaigns/Mutation";
import {
  ADD_COMMUNITIES,
  ADD_COMMUNITIES_USER,
  UPDATE_COMMUNITIES,
} from "~/graphql/brd_communities/Mutation";
import {
  GET_ALL_COMMUNTITIES,
  GET_COMMUNTITIES_USER,
} from "~/graphql/brd_communities/Subscription";
import { GET_USERS } from "~/graphql/users/Query";
import { ADD_USER } from "~/graphql/users/Mutation";
import { displayName } from "react-quill";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const CsvComp = ({ brdId }: any) => {
  const [data, setData] = useState<any>([]);
  const [fileName, setFileName] = useState<any>("");
  const [cityCodes, setCityCodes] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [progress, setProgress] = useState<any>(0);

  const client = useApolloClient();

  const [insertCommunities] = useMutation(ADD_COMMUNITIES);
  const [insertCommunitieUser] = useMutation(ADD_COMMUNITIES_USER);
  const [addUser] = useMutation(ADD_USER);

  const handleFileUpload = async (e: any) => {
    const file = e.target.files[0];
    setFileName(file.name);
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        setData(results.data);
      },
    });
  };

  useEffect(() => {
    const uniqueCityCodes = Array.from(
      new Set(data?.map((item: any) => item.cityCode))
    );
    setCityCodes(uniqueCityCodes);
  }, [data]);

  const generateCommunities = () => {
    setLoading(true);
    if (brdId?.length < 0 || brdId?.length > 1) {
      toast.error("Please Select a Brand");
      setLoading(false);
    } else {
      if (!data?.some((obj: any) => obj.hasOwnProperty("name"))) {
        toast.error(`The CSV file does not contain a column named "name"`);
        setLoading(false);
      } else if (!data?.some((obj: any) => obj.hasOwnProperty("cityCode"))) {
        toast.error(`The CSV file does not contain a column named "cityCode"`);
        setLoading(false);
      } else if (!data?.some((obj: any) => obj.hasOwnProperty("email"))) {
        toast.error(`The CSV file does not contain a column named "email"`);
        setLoading(false);
      } else if (!data?.some((obj: any) => obj.hasOwnProperty("phone"))) {
        toast.error(`The CSV file does not contain a column named "phone"`);
        setLoading(false);
      } else {
        let totalContacts = 0;
        let completedContacts = 0;

        const updateProgress = () => {
          completedContacts += 1;
          setProgress(Math.round((completedContacts / totalContacts) * 100));
        };

        Promise.all(
          cityCodes?.map((cityCode: any) => {
            return new Promise((resolve, reject) => {
              client
                .subscribe({
                  query: GET_ALL_COMMUNTITIES,
                  variables: {
                    condition: {
                      _and: [
                        { name: { _eq: cityCode } },
                        { brd_id: { _eq: brdId?.[0] } },
                      ],
                    },
                  },
                })
                .subscribe({
                  next(comunity) {
                    const contacts = data
                      ?.filter((obj: any) => obj.cityCode === cityCode)
                      ?.map((item: any) => ({
                        email: item.email,
                        phone: item.phone,
                        name: item.name,
                      }));

                    totalContacts += contacts.length;

                    const payload = {
                      name: cityCode,
                      brd_id: brdId?.[0],
                    };

                    const addComunUserData = async (
                      id: any,
                      communityId: any,
                      resolve: any
                    ) => {
                      client
                        .subscribe({
                          query: GET_COMMUNTITIES_USER,
                          variables: {
                            condition: {
                              _and: [
                                { community_id: { _eq: communityId } },
                                { user_id: { _eq: id } },
                              ],
                            },
                          },
                        })
                        .subscribe({
                          next(user: any) {
                            const userComunPayload = {
                              community_id: communityId,
                              user_id: id,
                            };

                            const addComunUser = async () => {
                              await insertCommunitieUser({
                                variables: { data: userComunPayload },
                              });
                            };

                            if (
                              user?.data?.brd_community_users &&
                              user?.data?.brd_community_users?.length < 1
                            ) {
                              updateProgress();
                              addComunUser()
                                .then(resolve)
                                .catch((error) => {});
                            } else {
                              updateProgress();
                              resolve(1);
                            }
                          },
                        });
                    };

                    const addUserData = async (
                      userData: any,
                      communityId: any,
                      resolve: any
                    ) => {
                      const userPayload = {
                        displayName: userData?.name,
                        email: userData?.email,
                        phoneNumber: userData?.phone,
                        metadata: { destinationIata: cityCode, type: "import" },
                        locale: "en",
                      };
                      const resUser = await addUser({
                        variables: { data: userPayload },
                      });
                      if (resUser?.data?.insertUsers?.returning?.[0]?.id) {
                        addComunUserData(
                          resUser?.data?.insertUsers?.returning?.[0]?.id,
                          communityId,
                          resolve
                        );
                      }
                    };

                    const checkUser = async (communityID: any) => {
                      await Promise.all(
                        contacts?.map((item: any) => {
                          return new Promise((resolve, reject) => {
                            client
                              .subscribe({
                                query: GET_USERS,
                                variables: {
                                  condition: {
                                    _or: [
                                      { phoneNumber: { _eq: item.phone } },
                                      { email: { _eq: item.email } },
                                    ],
                                  },
                                },
                              })
                              .subscribe({
                                next(userData: any) {
                                  if (
                                    userData?.data?.users &&
                                    userData?.data?.users?.length < 1
                                  ) {
                                    addUserData(item, communityID, resolve);
                                  } else {
                                    addComunUserData(
                                      userData?.data?.users?.[0]?.id,
                                      communityID,
                                      resolve
                                    );
                                  }
                                },
                              });
                          });
                        })
                      )
                        .then(resolve)
                        .catch((error) => {});
                    };

                    const addConv = async () => {
                      const communityID = await insertCommunities({
                        variables: { data: payload },
                      });
                      checkUser(
                        communityID?.data?.insert_brd_communities
                          ?.returning?.[0]?.id
                      );
                    };

                    if (
                      comunity?.data?.brd_communities &&
                      comunity?.data?.brd_communities?.length < 1
                    ) {
                      addConv();
                    } else {
                      checkUser(comunity?.data?.brd_communities?.[0]?.id);
                    }
                  },
                });
            });
          })
        )
          .then(() => {
            toast.success("Communities Generated Successfully");
            setLoading(false);
            setProgress(0);
          })
          .catch((error) => {
            setLoading(false);
            setProgress(0);
          });
      }
    }
  };

  return (
    <>
      <div className="w-full items-center">
        <p className="text-[10px] mb-1 text-center text-red-500">{`Columns name should be in camelCase { name, cityCode, email, phone } `}</p>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
          fullWidth
          sx={{ minWidth: "200px" }}
        >
          Upload CSV file
          <VisuallyHiddenInput
            type="file"
            accept=".csv"
            onChange={handleFileUpload}
          />
        </Button>
        {fileName && (
          <>
            <p className="text-[14px] mt-1 mb-3 text-center text-nowrap overflow-hidden truncate">
              {fileName}
            </p>
            <Button
              disabled={loading}
              variant="outlined"
              fullWidth
              onClick={generateCommunities}
            >
              {loading ? "Generating..." : "Generate Communities"}
            </Button>
            {loading && (
              <div className="text-[14px] mt-1 mb-3 flex gap-3 items-center w-full">
                <LinearProgress
                  sx={{ width: "100%" }}
                  variant="determinate"
                  value={progress}
                />
                <p>{progress}%</p>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CsvComp;
