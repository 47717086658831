import { gql } from "@apollo/client";

export const UPDATE_THP_HOUSE = gql`
  mutation updateThpHouse(
    $user_id: uuid!
    $custom_url: String!
    $data: thp_list_set_input!
  ) {
    update_thp_list(
      where: {
        _or: [
          { domain: { _eq: $custom_url } }
          { subdomain: { _eq: $custom_url } }
        ]
        _and: [
          {
            _or: [
              { brd_users: { users: { id: { _eq: $user_id } } } }
              { primary_user: { id: { _eq: $user_id } } }
            ]
          }
        ]
      }
      _set: $data
    ) {
      returning {
        id
      }
    }
  }
`;
