// Import react router v6
import { Routes, Route } from "react-router-dom";
// Import protected route
import { ProtectedRoute } from "./ProtectedRoute";

import Brands from "~/pages/brands/Brands";
import EditBrands from "~/pages/brands/EditBrand";
import EditFAQs from "~/pages/brands/EditFAQs";
import EditTestimonials from "~/pages/brands/EditTestimonials";
import PageSettings from "~/pages/brands/PageSettings";
import BrandDestinationPage from "~/pages/brands/BrandDestinationPage";
import BrandDestinationAddPage from "~/pages/brands/BrandDestinationAddPage";
import BrandDestinationUpdatePage from "~/pages/brands/BrandDestinationUpdatePage";
import AddBrandPage from "~/pages/brands/AddBrandPage";
import BrandPostsPage from "~/pages/brands/BrandPostsPage";
import BrandPostsUpdatePage from "~/pages/brands/BrandPostsUpdatePage";
import BrandPostsAddPage from "~/pages/brands/BrandPostsAddPage";
import BrandEmailTemplatePage from "~/pages/brands/BrandEmailTemplatePage";
import AnalyticsPage from "~/pages/brands/AnalyticsPage";
import BrandAirlinesPage from "~/pages/admin/BrandAirlinesPage";
import BrandAirlinesAddPage from "~/pages/admin/BrandAirlinesAddPage";
import BrandAirlinesUpdatePage from "~/pages/admin/BrandAirlinesUpdatePage";
import WhatsappPage from "~/pages/brands/WhatsappPage";
import InAppAlertsTemplatesPage from "~/pages/brands/InAppAlertsTemplatesPage";
import WhatsappTemplatesPage from "~/pages/brands/WhatsappTemplatesPage";
import ImportFlightPages from "~/pages/brands/ImportFlightPages";
import AutoBotMessagesPage from "~/pages/brands/AutoBotMessagesPage";
import EmailSettingPage from "~/pages/brands/EmailSettingPage";
import CustomerTimelinePage from "~/pages/brands/CustomerTimelinePage";
import BrdSeasonalFares from "~/pages/brands/BrdSeasonalFares";
import SocialConnectPage from "~/pages/brands/SocialConnectPage";

export default function BrandsRoutes() {
  return (
    <Routes>
      <Route path="/admin/brands" element={<ProtectedRoute role="admin" />}>
        <Route index element={<Brands />} />
        <Route path="add" element={<AddBrandPage />} />
        <Route path=":brd_id/edit" element={<EditBrands />} />
        <Route path=":brd_id/faqs" element={<EditFAQs />} />

        {/* <Route
          path=":brd_id/email-templates"
          element={<BrandEmailTemplatePage />}
        /> */}

        <Route
          path=":brd_id/email-templates"
          element={<BrandEmailTemplatePage />}
        />

        <Route
          path=":brd_id/in-app-alerts-templates"
          element={<InAppAlertsTemplatesPage />}
        />
        {/* <Route
          path=":brd_id/auto-bot-templates"
          element={<InAppAlertsTemplatesPage />}
        /> */}
        <Route
          path=":brd_id/whatsapp-templates"
          element={<WhatsappTemplatesPage />}
        />
        <Route path=":brd_id/brdSeasonalFares" element={<BrdSeasonalFares />} />
        <Route path=":brd_id/testimonials" element={<EditTestimonials />} />
        <Route path=":brd_id/page/:slug?" element={<PageSettings />} />
        <Route path=":brd_id/testimonials" element={<EditTestimonials />} />
        <Route path=":brd_id/destinations" element={<BrandDestinationPage />} />
        <Route
          path=":brd_id/destinations/add"
          element={<BrandDestinationAddPage />}
        />
        <Route
          path=":brd_id/destinations/update/:page_id"
          element={<BrandDestinationUpdatePage />}
        />
        <Route
          path=":brd_id/destinations/auto-bot-messages/:page_id"
          element={<AutoBotMessagesPage />}
        />
        <Route
          path=":brd_id/import-flight-pages"
          element={<ImportFlightPages />}
        />
        <Route path=":brd_id/airlines" element={<BrandAirlinesPage />} />
        <Route path=":brd_id/airlines/add" element={<BrandAirlinesAddPage />} />
        <Route
          path=":brd_id/airlines/update/:page_id"
          element={<BrandAirlinesUpdatePage />}
        />

        <Route path=":brd_id/posts" element={<BrandPostsPage />} />
        <Route path=":brd_id/posts/add" element={<BrandPostsAddPage />} />
        <Route
          path=":brd_id/posts/update/:post_id"
          element={<BrandPostsUpdatePage />}
        />
        <Route path=":brd_id/whatsapp" element={<WhatsappPage />} />
        <Route path=":brd_id/email-setting" element={<EmailSettingPage />} />
        <Route
          path=":brd_id/email-setting/:token"
          element={<EmailSettingPage />}
        />
        <Route
          path=":brd_id/customer/:user_id"
          element={<CustomerTimelinePage />}
        />
        <Route path=":brd_id/social-connect/" element={<SocialConnectPage />} />
      </Route>
    </Routes>
  );
}
