import React from "react";
import DateInterval from "./components/DateInterval";
import BrandsFilter from "./components/BrandsFilter";
import SingleInputDateRangePicker from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons/components/DateRangePicker";
import Brandfilter from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons/components/Brandfilter";
import SourceFilter from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons/components/SourceFilter";
import { GET_INQUIRIES_SOURCE_AD_ID } from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons/components/graphql/Query";

export default function Filters({
  dates,
  setDates,
  setBrands,
  brands,
  setSource,
  source,
}: any) {
  return (
    <div className="grid xl:grid-cols-3 md:grid-cols-2 gap-2 mb-2 w-full">
      <div className="min-w-[300px] mt-2">
        <SourceFilter
          allBrand={brands}
          setFilters={setSource}
          filters={source}
          target="source_add_id"
          label={"Ad Id"}
          query={GET_INQUIRIES_SOURCE_AD_ID}
        />
      </div>
      <div className="min-w-[300px]">
        <SingleInputDateRangePicker setDates={setDates} dates={dates} />
      </div>
      <div className="min-w-[300px]  mt-[8px] xl:col-span-1  md:col-span-2">
        <Brandfilter setSelectedBrand={setBrands} />
      </div>
      {/* <BrandsFilter setBrands={setBrands} brands={brands} /> */}
    </div>
  );
}
