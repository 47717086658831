// import InputField from "components/FormInputs/InputField";
import React from "react";
import InputField from "components/FormInputs/InputField";
import { CalendarBox } from "components/FormInputs/CalendarBox";

export default function FlightDates({
  errors,
  setValue,
  control,
  getValues,
  register,
}: any) {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-4">
        {/* Flight Fare Expiry */}
        <CalendarBox
          control={control}
          setValue={setValue}
          errors={errors}
          fieldName="fareExpiry"
          label="Fare Expiry"
          defaultValue={getValues(`fareExpiry`) || ""}
          inputClasses="text-primary dark:text-dark-primary dark:bg-dark-primary focus:outline-none focus:border-basic"
          labelClasses="text-primary dark:text-dark-primary"
        />
        {/* Flight Invoice Due Date */}
        <CalendarBox
          control={control}
          setValue={setValue}
          errors={errors}
          fieldName="invoiceDueDate"
          label="Invoice Due Date"
          defaultValue={getValues(`invoiceDueDate`) || ""}
          inputClasses="text-primary dark:text-dark-primary dark:bg-dark-primary focus:outline-none focus:border-basic"
          labelClasses="text-primary dark:text-dark-primary"
        />
      </div>
      <div className="flex flex-row gap-4 w-full">
        <div className="w-full">
          <label className="text-[14px] text-primary dark:text-dark-primary">PNR</label>
          <input
            defaultValue={getValues(`pnr`) || ""}
            type="text"
            {...register("pnr")}
            className="h-[35px] text-[14px] min-w-[300px] w-full rounded p-2 border-gray-300 border text-primary dark:text-dark-primary dark:bg-dark-primary focus:outline-none focus:border-basic"
          />
        </div>
        <div className="w-full">
          <label className="text-[14px] text-primary dark:text-dark-primary">Booking Fee</label>
          <input
            defaultValue={getValues(`bookingFee`) || ""}
            type="text"
            {...register("bookingFee")}
            className="h-[35px] text-[14px] min-w-[300px] w-full rounded p-2 border-gray-300 border text-primary dark:text-dark-primary dark:bg-dark-primary focus:outline-none focus:border-basic"
          />
        </div>
      </div>
    </div>
  );
}
