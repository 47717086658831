import { gql } from "@apollo/client";

export const CREATE_LINKEDIN_POST = gql`
  query CREATE_LINKEDIN_POST(
    $access_token: String!
    $id: String!
    $author: String!
    $lifecycleState: String!
    $shareCommentaryText: String!
    $shareMediaCategory: String!
    $mediaStatus: String!
    $mediaDescription: String!
    $mediaTitleText: String!
    $mediaOrgURL: String!
    $visibility: String!
    $mediaImage: String!
  ) {
    createlinkedinPost(
      arg1: {
        access_token: $access_token
        id: $id
        author: $author
        lifecycleState: $lifecycleState
        shareCommentaryText: $shareCommentaryText
        shareMediaCategory: $shareMediaCategory
        mediaStatus: $mediaStatus
        mediaDescription: $mediaDescription
        mediaTitleText: $mediaTitleText
        mediaOrgURL: $mediaOrgURL
        visibility: $visibility
        mediaImage: $mediaImage
      }
    ) {
      id
    }
  }
`;
