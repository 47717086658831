import { useUserDefaultRole } from "@nhost/react";
import React from "react";

export default function InquiresHead({ userCol = true }: any) {
  const role = useUserDefaultRole();
  return (
    <thead className="bg-secondary rounded-t-lg shadow dark:bg-dark-secondary text-primary dark:text-dark-primary">
      <tr>
        {/* <th scope="col" className="p-4">
          <div className="flex items-center">
            <input
              id="checkbox-all"
              aria-describedby="checkbox-1"
              type="checkbox"
              className="w-4 h-4 border-gray-300 rounded bg-primary dark:bg-dark-primary"
            />
            <label htmlFor="checkbox-all" className="sr-only">
              checkbox
            </label>
          </div>
        </th> */}
        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase"
        >
          ID & <br />
          Received Time
        </th>
        {role !== "user" && (
          <>
            {userCol && (
              <th
                scope="col"
                className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase"
              >
                User
              </th>
            )}
          </>
        )}

        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase"
        >
          Consultant
        </th>

        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase"
        >
          Origin / Destination
        </th>
        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase "
        >
          Date
        </th>
        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase "
        >
          Status
        </th>
        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase"
        >
          Passenger <br />& Budget
        </th>

        {role !== "user" && (
          <th
            scope="col"
            className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase"
          >
            Source <br />
          </th>
        )}

        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase"
        >
          Action
        </th>
      </tr>
    </thead>
  );
}
