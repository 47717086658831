import React, { useEffect, useState } from "react";
import InquiryFilterButtons from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons";

import { useForm } from "react-hook-form";
import SidePopup from "~/components/common/SidePopup";
import { filterStatus } from "~/modules/InquiriesModule/data/filters";
import InquiresList from "./components/InquiresList";

import dayjs from "dayjs";
import { TextField } from "@mui/material";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { useApolloClient, useQuery } from "@apollo/client";
import {
  GET_CONSULTANTS,
  GET_INQ_FLAGS,
} from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons/components/graphql/Query";
import InquiryAdd from "../InquiryAdd/InquiryAdd";
import { GET_ASSIGNED_BRANDS } from "~/modules/DashboardModule/segments/DashboardStats/graphql/Query";
import {
  useUserDefaultRole,
  useUserDisplayName,
  useUserId,
} from "@nhost/react";
import { useSearchParams } from "react-router-dom";

const Inquiries = () => {
  const [searchParam] = useSearchParams();

  const client = useApolloClient();

  const urlStartTime: any = searchParam.get("startTime");
  const urlEndTime: any = searchParam.get("endTime");
  const urlConsId: any = searchParam.get("consultant");
  const urlStatus: any = searchParam.get("status");

  const userId = useUserId();
  const { travelHouse }: any = travelHouseStore();
  const { loading, data, error } = useQuery(GET_INQ_FLAGS, {
    variables: {
      id: travelHouse?.id,
    },
  });
  const {
    loading: brdLoading,
    data: brdData,
    error: brdError,
  } = useQuery(GET_ASSIGNED_BRANDS, {
    variables: {
      thp_id: travelHouse?.id,
      id: userId,
    },
  });

  const logedUserId = useUserId();
  const userName = useUserDisplayName();

  const userRole = useUserDefaultRole();

  let consultant: any = [];

  if (userRole === "consultant") {
    consultant.push({ id: logedUserId, displayName: userName });
  }

  const [search, setSearch] = useState<String>("");
  const [selectedStatus, setSelectedStatus] = useState<any>();
  const [selectedFlags, setSelectedFlags] = useState<any>([]);
  const [selectedConsultant, setSelectedConsultant] = useState<any>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<any>([]);
  const [selectedBrand, setSelectedBrand] = useState<any>([]);
  const [allBrand, setAllBrand] = useState<any>([]);
  const [source, setSource] = useState<any>({
    source: null,
    source_place: null,
    source_position: null,
    source_keyword: null,
    source_add_id: null,
  });
  const [locations, setLocations] = useState<any>({
    from: null,
    to: null,
  });

  const today = dayjs();

  const currentMonth = [
    today.startOf("month").subtract(1, "month"),
    today.endOf("month"),
  ];

  const [dates, setDates] = useState<any>(currentMonth);

  useEffect(() => {
    const runFunc = async () => {
      const res = await client.query({
        query: GET_CONSULTANTS,
        variables: {
          thp_id: travelHouse?.id,
        },
      });

      const filteredCons = res?.data?.users?.filter(
        (item: any) => item.id === urlConsId
      );

      setSelectedConsultant(filteredCons?.[0]);
    };

    if (urlConsId) {
      runFunc();
    }

    if (urlStartTime || urlEndTime) {
      setDates([dayjs(urlStartTime), dayjs(urlEndTime)]);
    }

    if (urlStatus) {
      const filteredStatus = filterStatus.filter(
        (item: any) => item.value === urlStatus
      );

      setSelectedStatus(filteredStatus?.[0]);
    }
  }, [urlStartTime, urlEndTime, urlConsId]);

  useEffect(() => {
    if (data?.thp_inquiry_flags) {
      setSelectedFlags(data?.thp_inquiry_flags);
    }
  }, [data]);

  useEffect(() => {
    const brdRec: any = [];
    if (brdData) {
      if (brdData?.brd_users?.length > 0) {
        brdData?.brd_users?.map((brd: any) => {
          brdRec.push({ id: brd?.brd_id, name: brd.brd_list.name });
        });
      } else {
        brdData?.thp_list?.[0].brd_lists?.map((brd: any) => {
          brdRec.push({ id: brd?.id, name: brd.name });
        });
      }
      setAllBrand(brdRec);
    }
  }, [brdData]);

  if (loading) return <>Loading..</>;
  if (error) return <>Err..${error.message}</>;
  if (brdLoading) return <>Loading..</>;
  if (brdError) return <>Err..${brdError.message}</>;

  return (
    <div>
      <div id="main-content" className="relative w-full h-full overflow-y-auto">
        <div className="p-4 bg-secondary dark:bg-dark-secondary block sm:flex items-center shadow rounded-t-lg justify-between lg:mt-1.5 relative ">
          <div className="w-full mb-1">
            <div className="items-center  justify-between block sm:flex ">
              <div className="flex flex-col md:flex-row gap-[20px]  items-center mb-4 sm:mb-0 w-full">
                <InquiryFilterButtons
                  selectedStatus={selectedStatus}
                  setSelectedStatus={setSelectedStatus}
                  dates={dates}
                  setDates={setDates}
                  selectedFlags={selectedFlags}
                  setSelectedFlags={setSelectedFlags}
                  selectedConsultant={selectedConsultant}
                  setSelectedConsultant={setSelectedConsultant}
                  setSelectedCustomer={setSelectedCustomer}
                  setSearch={setSearch}
                  search={search}
                  setSelectedBrand={setSelectedBrand}
                  allBrand={allBrand}
                  setSource={setSource}
                  source={source}
                  setLocations={setLocations}
                  locations={locations}
                  travelHouse={travelHouse}
                  brdData={brdData}
                />
              </div>
            </div>
          </div>
        </div>

        <InquiresList
          travelHouse={travelHouse}
          search={search}
          selectedStatus={selectedStatus}
          allStatus={filterStatus}
          allFlags={data?.thp_inquiry_flags}
          selectedFlags={selectedFlags}
          dates={dates}
          selectedConsultant={selectedConsultant}
          selectedCustomer={selectedCustomer}
          selectedBrand={selectedBrand}
          allBrand={allBrand}
          source={source}
          locations={locations}
        />

        <SidePopup component={<InquiryAdd />} text="Add Inquiry" />
      </div>
    </div>
  );
};

export default Inquiries;
