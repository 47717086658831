import { useQuery } from "@apollo/client";
import { Autocomplete, TextField } from "@mui/material";
import { GET_CONSULTANTS, GET_INQ_FLAGS } from "./graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { useEffect, useState } from "react";
import { useUserId } from "@nhost/react";
import { GET_ASSIGNED_BRANDS } from "~/modules/DashboardModule/segments/DashboardStats/graphql/Query";
import useAssigedBrands from "~/hooks/useAssigedBrands";

const Brandfilter = ({ setSelectedBrand, defaultSelected = null }: any) => {
  const { travelHouse }: any = travelHouseStore();
  const userId = useUserId();

  const { brands } = useAssigedBrands();

  const handleFlagChange = (event: any, newValue: any) => {
    setSelectedBrand([newValue]);
  };

  // const [brands, setBrands] = useState([]);

  // useEffect(() => {
  //   const brdRec: any = [];
  //   if (brdData) {
  //     if (brdData?.brd_users?.length > 0) {
  //       brdData?.brd_users?.map((brd: any) => {
  //         brdRec.push({ id: brd?.brd_id, name: brd.brd_list.name });
  //       });
  //     } else {
  //       brdData?.thp_list?.[0].brd_lists?.map((brd: any) => {
  //         brdRec.push({ id: brd?.id, name: brd.name });
  //       });
  //     }
  //     setBrands(brdRec);
  //   }
  // }, [brdData]);

  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={brands || []}
        onChange={handleFlagChange}
        getOptionLabel={(option: any) => option?.name}
        sx={{ width: "100%" }}
        renderInput={(params) => <TextField {...params} label="Brands" />}
      />
    </>
  );
};

export default Brandfilter;
