import { useEffect } from "react";
import InputField from "~/components/FormInputs/InputField";
import ContentEditor from "~/components/common/ContentEditor";

const EmailTemplatesInputs = ({
  register,
  errors,
  template,
  content,
  setContent,
  setValue,
  selectedRole,
  handleRoleChange,
  selectedDynamicFrom,
  handleDynamicFromChange,
  selectedIsActivity,
  handleActivityChange,
}: any) => {
  useEffect(() => {
    if (template?.content) {
      setValue("subject", template.subject);
      setValue("variables_allowed", template.variables_allowed);
      setContent(template.content);
    }
  }, [template, setValue, setContent]);

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col">
        <label>Role</label>
        <select
          value={selectedRole}
          onChange={handleRoleChange}
          className="mt-2 p-2 border rounded-md"
        >
          <option value="admin">admin</option>
          <option value="user">user</option>
          <option value="consultant">consultant</option>
          <option value="accountant">accountant</option>
        </select>
      </div>
      <div className="col-span-6 sm:col-full">
        <InputField
          register={register}
          errors={errors}
          fieldName={`variables_allowed`}
          type="text"
          inputClasses="shadow border-none"
          label="Variables Allowed"
          placeHolder="Variables"
          defaultValue={template.variables_allowed}
        />
      </div>
      <div className="col-span-6 sm:col-full">
        <InputField
          register={register}
          errors={errors}
          fieldName={`subject`}
          type="text"
          inputClasses="shadow border-none"
          label="Subject"
          placeHolder="Enter Post Title"
          defaultValue={template.subject}
        />
      </div>
      {/* <div className="col-span-6 sm:col-full mt-2">
        <label className="mb-2 block">Content</label>
        <ContentEditor content={content} setContent={setContent} />
      </div> */}
      <div className="col-span-6 sm:col-full">
        <InputField
          register={register}
          errors={errors}
          fieldName={`whatsapp_template_name`}
          type="text"
          inputClasses="shadow border-none"
          label="Whatsapp Template Name"
          placeHolder="Enter Whatsapp Template Name"
          defaultValue={template.whatsapp_template_name}
        />
      </div>
      <div className="col-span-6 sm:col-full">
        <InputField
          register={register}
          errors={errors}
          fieldName={`notification_content`}
          type="text"
          inputClasses="shadow border-none"
          label="Notification Content"
          placeHolder="Enter Notification Content"
          defaultValue={template.notification_content}
        />
      </div>
      <div className="flex flex-col">
        <label>Dynamic from</label>
        <select
          value={selectedDynamicFrom}
          onChange={handleDynamicFromChange}
          className="mt-2 p-2 border rounded-md"
        >
          <option value="true">true</option>
          <option value="false">false</option>
        </select>
      </div>
      <div className="col-span-6 sm:col-full">
        <InputField
          register={register}
          errors={errors}
          fieldName={`dynamic_from_label`}
          type="text"
          inputClasses="shadow border-none"
          label="Dynamic From (Label)"
          placeHolder="Enter Label"
          defaultValue={template.dynamic_from_label}
        />
      </div>
      <div className="flex flex-col">
        <label>Activity</label>
        <select
          value={selectedIsActivity === null ? "" : selectedIsActivity}
          onChange={handleActivityChange}
          className="mt-2 p-2 border rounded-md"
        >
          <option value="true">true</option>
          <option value="false">false</option>
          <option value="">null</option>
        </select>
      </div>
    </div>
  );
};

export default EmailTemplatesInputs;
