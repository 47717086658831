import { gql } from "@apollo/client";

export const GET_LINKEDIN_ORGANIZATION_NAMES = gql`
  query GetLinkedInOrganizationNames($access_token: String!, $id: String!) {
    get_linkedin_u_organization_names(
      arg1: { access_token: $access_token, id: $id }
    ) {
      vanityName
      name {
        localized {
          en_US
        }
      }
    }
  }
`;
