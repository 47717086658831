import { useApolloClient, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { EDIT_INQUIRY } from "../../../../graphql/Mutation";
import { toast } from "react-toastify";
import {
  useUserDefaultRole,
  useUserDisplayName,
  useUserId,
} from "@nhost/react";
import useNotifications from "hooks/useNotifications";
import useEmail from "~/hooks/emails/useEmail";
import { UPDATE_INQ_UPDATE_USER } from "~/graphql/inq_list/Mutation";
import { useEffect } from "react";
import Unselect from "../../../../../../../../assets/img/Unselect.svg"
import UnselectDark from "../../../../../../../../assets/img/Unselect-dark.svg"

export default function SuggestionUnSelect({
  inquiry_id,
  suggestion_id,
  picked_by,
  user_id,
  inquiry,
}: any) {
  const [modalOpen, setModalOpen] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const client = useApolloClient();
  const alert = useNotifications();
  const userRole = useUserDefaultRole();
  const emailSend = useEmail();
  const userName = useUserDisplayName();
  const [editInquiry] = useMutation(EDIT_INQUIRY);

  const [updateInqListUser] = useMutation(UPDATE_INQ_UPDATE_USER);

  const userId = useUserId();

  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);

    const handleStorageChange = () => {
      const updatedData = localStorage.getItem("darkMode");
      setDarkMode(updatedData ? JSON.parse(updatedData) : false);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleConfirm = async (event: any) => {
    event.stopPropagation();
    setIsloading(true);

    try {
      // Create passenger function call
      await updateInqListUser({
        variables: { id: inquiry.id, updated_by: userId },
      });

      const res = await editInquiry({
        variables: { id: inquiry_id, selected_suggestion_id: null },
      });

      if (res.data?.update_inq_list?.returning?.length > 0) {
        toast.success("Suggestion Unselected successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        if (userRole === "user") {
          await alert.newNotification(
            picked_by,
            `Your customer has unselected fare options for you.`,
            "",
            ``
          );
        } else {
          const variables: any = {
            inquiryNo: inquiry?.inquiry_no,
            userFirstName: inquiry?.users?.displayName,
            userLastName: "",
            consultantName: userName,
            brandName: inquiry?.brd_list?.name,
          };

          // ;
          await emailSend(
            6,
            inquiry.brd_id,
            inquiry.users?.id,
            inquiry.users?.email,
            inquiry.users?.phoneNumber,
            variables
          );

          await alert.newNotification(
            user_id,
            `Your consultant has unselected fare options for you.`,
            "",
            ``
          );
        }
        await client.resetStore();
      }
      //   setIsloading(false); // change button loading state
      setModalOpen(false);
      setIsloading(false);
    } catch (e) {
      setModalOpen(false);
      setIsloading(false);
      console.error("error", e);
    }
  };
  return (
    <>
      {/* <span
        onClick={() => {
          setModalOpen(true);
        }}
        className=" bg-basic cursor-pointer rounded-[5px] mt-2 py-2 px-5 text-white"
      >
        {isloading ? "Unselecting.." : "Unselect"}
      </span> */}

      <img
        src={darkMode ? UnselectDark : Unselect}
        alt=""
        title="Unselect Suggestion"
        className="cursor-pointer"
        onClick={() => {
          setModalOpen(true);
        }}
      />

      {modalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          style={{ zIndex: 50 }}
        >
          <div className="relative bg-white dark:bg-dark-primary rounded-xl shadow-2xl p-8 w-11/12 sm:w-3/4 md:w-1/2 lg:w-1/3">
            <h2 className="mb-6 text-2xl font-bold text-gray-700 dark:text-dark-primary">
              Are you sure you want to unselect this suggestion?
            </h2>
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={(event: any) => setModalOpen(false)}
                className="px-5 py-1 font-semibold text-basic rounded-lg border border-basic"
              >
                No
              </button>
              <button
                onClick={(event: any) => handleConfirm(event)}
                className="px-5 py-1 font-semibold text-white bg-basic rounded-lg"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
