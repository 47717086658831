import { gql } from "@apollo/client";

export const GET_MESSAGES = gql`
  subscription Mysubscription($conversation_id: uuid) {
    brd_gmail_messages(
      where: { conversation_id: { _eq: $conversation_id } }
      order_by: { timestamp: asc }
    ) {
      id
      from
      content
      subject
      timestamp
      created_at
      updated_at
      to
      message_id
      files
      history_id
    }
  }
`;

export const GET_CONVERSATION_MESSAGES = gql`
  subscription Mysubscription($id: uuid) {
    brd_whatsapp_conversations(where: { id: { _eq: $id } }) {
      id
      user_phone
      brand_phone
      whatsapp_name
      last_view_time
      created_at
      updated_at
      user_id
      last_message
      last_message_time
      messages {
        id
        conversation_id
        sender_role
        text
        timestamp
        created_at
        updated_at
      }
    }
  }
`;

export const FETCH_UPDATE = gql`
  subscription MySubscription(
    $timestamp: timestamptz!
    $conversation_id: uuid!
  ) {
    brd_whatsapp_messages_stream(
      batch_size: 10
      cursor: { initial_value: { timestamp: $timestamp } }
      where: { conversation_id: { _eq: $conversation_id } }
    ) {
      id
      timestamp
    }
  }
`;

export const UPDATE_CONVERSATION = gql`
  mutation MyMutation($conversation_id: uuid, $time: timestamptz) {
    update_brd_gmail_conversation(
      where: { id: { _eq: $conversation_id } }
      _set: { last_view_time: $time }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const GET_CONVERSATION_DETAIL = gql`
  subscription MyQuery($id: uuid) {
    brd_gmail_conversation(where: { id: { _eq: $id } }) {
      user_email
      user_name
      last_email_subject
      last_email_time
      last_view_time
      brd_list {
        name
        gmail_refresh_token
      }
    }
  }
`;
