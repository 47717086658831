import React, { useState } from "react";

import { InquiresTabs } from "./data/InquiresTabs";
import SingleButton from "./components/SingleButton";
import { useUserDefaultRole } from "@nhost/react";
import { MultiSelect } from "@mantine/core";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { filterStatus } from "~/modules/InquiriesModule/data/filters";
import DateInterval from "~/modules/DashboardModule/segments/DashboardStats/components/Filters/components/DateInterval";
import DateRangePickerCustom from "./components/DateRangePicker";
import { Button } from "@mui/material";
import InquiryFlagFilter from "./components/InquiryFlagFilter";
import Consultantfilter from "./components/Consultantfilter";
import { useForm } from "react-hook-form";
import CustomerFilter from "./components/CustomerFilter";
import Brandfilter from "./components/Brandfilter";
import SourceFilter from "./components/SourceFilter";

import {
  GET_INQUIRIES_DESTINATION,
  GET_INQUIRIES_ORIGIN,
  GET_INQUIRIES_SOURCES,
  GET_INQUIRIES_SOURCE_AD_ID,
  GET_INQUIRIES_SOURCE_KEYWORD,
  GET_INQUIRIES_SOURCE_PLACE,
  GET_INQUIRIES_SOURCE_POSITION,
} from "./components/graphql/Query";

export default function InquiryFilterButtons({
  selectedFlags,
  setSelectedFlags,
  selectedStatus,
  setSelectedStatus,
  selectedConsultant,
  setSelectedConsultant,
  setSelectedCustomer,
  setSelectedBrand,
  dates,
  setDates,
  setSearch,
  search,
  allBrand,
  setSource,
  source,
  setLocations,
  locations,
  travelHouse,
  brdData,
}: any) {
  const role = useUserDefaultRole();

  const [inquiryId, setInquiryId] = useState<any>("");

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Save Passsenger function
  const onSubmit = async (data: any) => {
    setSearch(inquiryId);
  };

  const handleStatusChange = (event: any, newValue: any) => {
    setSelectedStatus(newValue);
  };

  const userRole = useUserDefaultRole();

  return (
    <div className="w-full bg-secondary dark:bg-dark-secondary">
      <div className="grid xl:grid-cols-4 md:grid-cols-2 items-center w-full ">
        <div className="relative pl-2 pt-[8px]">
          <DateRangePickerCustom dates={dates} setDates={setDates} />
        </div>
        <form
          className="flex pl-2 space-x-1 w-full overflow-x-auto pt-[8px] mt-[8px]"
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            id="outlined-basic"
            label="Inquiry Id"
            variant="outlined"
            name="inquiry_id"
            value={search}
            className="w-full"
            onChange={(e) => setSearch(e.target.value)}
          />
        </form>
        <div className="flex pl-2 space-x-1 w-full overflow-x-auto pt-[8px]  mt-[8px]">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={filterStatus}
            onChange={handleStatusChange}
            value={selectedStatus}
            getOptionLabel={(option: any) => option.title}
            sx={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Status" />}
          />
        </div>
        <div className="flex pl-2 space-x-1 w-full overflow-x-auto w-[150px] pt-[8px]  mt-[8px]">
          <InquiryFlagFilter
            selectedFlags={selectedFlags}
            setSelectedFlags={setSelectedFlags}
            travelHouse={travelHouse}
          />
        </div>
        {/* {userRole === "admin" && ( */}
        <div className="flex pl-2 space-x-1 w-full overflow-x-auto w-[150px] pt-[8px]  mt-[8px]">
          <Consultantfilter
            setSelectedConsultant={setSelectedConsultant}
            selectedConsultant={selectedConsultant}
          />
        </div>
        {/* // )} */}

        <div className="flex pl-2 space-x-1 w-full overflow-x-auto w-[150px] pt-[8px]  mt-[8px]">
          <CustomerFilter
            setSelectedCustomer={setSelectedCustomer}
            travelHouse={travelHouse}
          />
        </div>
        <div className="flex pl-2 space-x-1 w-full overflow-x-auto w-[150px] pt-[8px]  mt-[8px]">
          <Brandfilter setSelectedBrand={setSelectedBrand} />
        </div>
        <div className="flex pl-2 space-x-1 w-full overflow-x-auto min-w-[300px] pt-[8px]  mt-[8px]">
          <SourceFilter
            allBrand={allBrand}
            setFilters={setSource}
            filters={source}
            target="source"
            label={"Source"}
            query={GET_INQUIRIES_SOURCES}
          />
        </div>
        <div className="flex pl-2 space-x-1 w-full overflow-x-auto w-[150px] pt-[8px]  mt-[8px]">
          <SourceFilter
            allBrand={allBrand}
            setFilters={setSource}
            filters={source}
            target="source_place"
            label={"Place"}
            query={GET_INQUIRIES_SOURCE_PLACE}
          />
        </div>

        <div className="flex pl-2 space-x-1 w-full overflow-x-auto w-[150px] pt-[8px]  mt-[8px]">
          <SourceFilter
            allBrand={allBrand}
            setFilters={setSource}
            filters={source}
            target="source_position"
            label={"Position"}
            query={GET_INQUIRIES_SOURCE_POSITION}
          />
        </div>

        <div className="flex pl-2 space-x-1 w-full overflow-x-auto w-[150px] pt-[8px]  mt-[8px]">
          <SourceFilter
            allBrand={allBrand}
            setFilters={setSource}
            filters={source}
            target="source_keyword"
            label={"Keyword"}
            query={GET_INQUIRIES_SOURCE_KEYWORD}
          />
        </div>
        <div className="flex pl-2 space-x-1 w-full overflow-x-auto w-[150px] pt-[8px]  mt-[8px]">
          <SourceFilter
            allBrand={allBrand}
            setFilters={setSource}
            filters={source}
            target="source_add_id"
            label={"Ad Id"}
            query={GET_INQUIRIES_SOURCE_AD_ID}
          />
        </div>
        <div className="flex pl-2 xl:col-span-2 space-x-1 w-full overflow-x-auto w-[150px] pt-[8px]  mt-[8px]">
          <SourceFilter
            allBrand={allBrand}
            setFilters={setLocations}
            filters={locations}
            target="from"
            label={"Origin"}
            query={GET_INQUIRIES_ORIGIN}
          />
        </div>
        <div className="flex pl-2 xl:col-span-2 space-x-1 w-full overflow-x-auto w-[150px] pt-[8px]  mt-[8px]">
          <SourceFilter
            allBrand={allBrand}
            setFilters={setLocations}
            filters={locations}
            target="to"
            label={"Destination"}
            query={GET_INQUIRIES_DESTINATION}
          />
        </div>
      </div>
    </div>
  );
}
