import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useEffect, useState } from "react";
import {
  useApolloClient,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import {
  GET_ALL_COMMUNTITIES,
  GET_COMMUNITIES,
  GET_COMMUNTITIES_USER,
} from "~/graphql/brd_communities/Subscription";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import {
  GET_ALL_INQUIRIES,
  GET_ALL_INQUIRIES_QUERY,
} from "~/modules/DashboardModule/segments/DashboardStats/components/Charts/graphql/Query";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import {
  ADD_COMMUNITIES,
  ADD_COMMUNITIES_USER,
  DELETE_COMMUNITIES_USER,
  UPDATE_COMMUNITIES,
} from "~/graphql/brd_communities/Mutation";
import {
  Autocomplete,
  Box,
  Button,
  LinearProgress,
  TextField,
} from "@mui/material";
import ChatRow from "../../../../../ChatRow";
import CsvComp from "../CsvComp";
import SearchBar from "../../../../../SearchBar";
import ChatRowSekeleton from "~/components/whatsapp/SideBarSekeleton/component/ChatRowSekeleton";
import { toast } from "react-toastify";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErrorIcon from "@mui/icons-material/Error";

const CommunityComp = ({
  showCommunityBar,
  setShowCommunityBar,
  brand_phone,
  // brd_ids,
  type,
}: any) => {
  const { brd_ids, brands } = useAssigedBrands();

  const [brandIDs, setBrandIds] = useState<any>(brd_ids);
  const [inquiries, setInquiries] = useState<any>([]);
  const [conversations, setConversations] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [progress, setProgress] = useState<any>(0);

  const [communitySearchTerm, setCommunitySearchTerm] = useState("");
  const [offset, setOffset] = useState<any>(0);
  const [errorMessage, setErrorMessage] = useState<any>("");

  const userId = useUserId();
  const defaultRole = useUserDefaultRole();

  const client = useApolloClient();

  const [insertCommunities] = useMutation(ADD_COMMUNITIES);
  const [insertCommunitieUser] = useMutation(ADD_COMMUNITIES_USER);

  useEffect(() => {
    if (!brandIDs?.[0]) {
      setBrandIds(brd_ids);
    }
  }, [brd_ids, brandIDs]);

  useEffect(() => {
    if (brandIDs?.length > 0) {
      client
        .subscribe({
          query: GET_ALL_COMMUNTITIES,
          variables: {
            condition: {
              brd_id: { _in: brandIDs },
            },
            offset: offset,
          },
        })
        .subscribe({
          next(data) {
            const sortedConv = data?.data?.brd_communities?.sort(function (
              a: any,
              b: any
            ) {
              return b?.userIds_length - a?.userIds_length;
            });

            const filteredConversations = sortedConv?.filter((conv: any) =>
              conv.brd_community_users?.some((user: any) =>
                inquiries?.some(
                  (inq: any) =>
                    user.user_id === inq.users.id && conv.iata === inq.to
                )
              )
            );

            if (defaultRole === "admin") {
              setConversations([...conversations, ...sortedConv]);
            } else {
              setConversations([...conversations, ...filteredConversations]);
            }
          },
        });
    }
  }, [brandIDs, offset]);

  useEffect(() => {
    setOffset(0);

    if (brandIDs?.length > 0 && communitySearchTerm?.length > 2) {
      client
        .subscribe({
          query: GET_ALL_COMMUNTITIES,
          variables: {
            condition: {
              brd_id: { _in: brandIDs },
              ...(communitySearchTerm
                ? {
                    _or: [{ name: { _ilike: `%${communitySearchTerm}%` } }],
                  }
                : {}),
            },
            offset: offset,
          },
        })
        .subscribe({
          next(data) {
            const sortedConv = data?.data?.brd_communities?.sort(function (
              a: any,
              b: any
            ) {
              return b?.userIds_length - a?.userIds_length;
            });

            const filteredConversations = sortedConv?.filter((conv: any) =>
              conv.brd_community_users?.some((user: any) =>
                inquiries?.some(
                  (inq: any) =>
                    user.user_id === inq.users.id && conv.iata === inq.to
                )
              )
            );

            if (defaultRole === "admin") {
              setConversations(sortedConv);
            } else {
              setConversations(filteredConversations);
            }
          },
        });
    }

    if (brandIDs?.length > 0 && !communitySearchTerm) {
      client
        .subscribe({
          query: GET_ALL_COMMUNTITIES,
          variables: {
            condition: {
              brd_id: { _in: brandIDs },
            },
            offset: offset,
          },
        })
        .subscribe({
          next(data) {
            const sortedConv = data?.data?.brd_communities?.sort(function (
              a: any,
              b: any
            ) {
              return b?.userIds_length - a?.userIds_length;
            });

            const filteredConversations = sortedConv?.filter((conv: any) =>
              conv.brd_community_users?.some((user: any) =>
                inquiries?.some(
                  (inq: any) =>
                    user.user_id === inq.users.id && conv.iata === inq.to
                )
              )
            );

            if (defaultRole === "admin") {
              setConversations(sortedConv);
            } else {
              setConversations(filteredConversations);
            }
          },
        });
    }
  }, [communitySearchTerm]);

  useEffect(() => {
    setTimeout(() => {
      if (conversations?.length < 1) {
        setErrorMessage("No Conversations Found");
      }
    }, 5000);
  }, [conversations]);

  const condition = {
    brd_id: { _in: brandIDs },
  };

  const { data, error } = useQuery(GET_ALL_INQUIRIES_QUERY, {
    variables: {
      condition,
    },
  });

  useEffect(() => {
    setInquiries(data?.inq_list);
  }, [data?.inq_list]);

  const uniqueToByBrdId: { [key: string]: Set<any> } = {};

  // Iterate over inquiries to populate unique 'to' values for each 'brd_id'
  inquiries?.forEach((item: any) => {
    const { to, brd_id } = item;
    if (!uniqueToByBrdId[brd_id]) {
      uniqueToByBrdId[brd_id] = new Set();
    }
    if (!uniqueToByBrdId[brd_id].has(to)) {
      // Check if 'to' is not already present for this 'brd_id'
      uniqueToByBrdId[brd_id].add(to);
    }
  });

  // Initialize an object to keep track of 'to' values already added
  const addedToValues: { [key: string]: Set<any> } = {};

  // Initialize an array to store unique 'to' values for each 'brd_id'
  const uniqueToForEachBrdId: { to: any; brd_id: any }[] = [];

  // Iterate over inquiries again to construct the result array
  inquiries?.forEach((item: any) => {
    const { to, brd_id } = item;
    if (!addedToValues[to]) {
      addedToValues[to] = new Set();
    }
    // Check if 'to' value is not already added for a different 'brd_id'
    if (!addedToValues[to].has(brd_id)) {
      uniqueToForEachBrdId.push({ to, brd_id });
      addedToValues[to].add(brd_id);
    }
  });

  const reGenerateComu = () => {
    if (uniqueToForEachBrdId?.length > 0) {
      setLoading(true);

      let totalIds = 0;
      let completedIds = 0;

      const updateProgress = () => {
        completedIds += 1;
        setProgress(Math.round((completedIds / totalIds) * 100));
      };

      Promise.all(
        uniqueToForEachBrdId?.map((item: any, index: any) => {
          return new Promise((resolve, reject) => {
            const condition = {
              to: { _eq: item?.to },
              _and: { brd_id: { _eq: item?.brd_id } },
            };

            client
              .subscribe({
                query: GET_ALL_COMMUNTITIES,
                variables: {
                  condition: {
                    _and: [
                      { name: { _eq: item?.to } },
                      { brd_id: { _eq: item?.brd_id } },
                    ],
                  },
                },
              })
              .subscribe({
                next(comunity) {
                  client
                    .subscribe({
                      query: GET_ALL_INQUIRIES,
                      variables: {
                        condition,
                      },
                    })
                    .subscribe({
                      next(data: any) {
                        const userIDs = data?.data?.inq_list?.map(
                          (item: any) => {
                            return item?.users?.id;
                          }
                        );
                        const uniqueUserIDs = Array.from(new Set(userIDs));

                        totalIds += uniqueUserIDs.length;

                        const payload = {
                          name: item?.to,
                          brd_id: item?.brd_id,
                        };

                        const addConv = async () => {
                          const communityID = await insertCommunities({
                            variables: { data: payload },
                          });
                          await Promise.all(
                            uniqueUserIDs?.map((item: any) => {
                              return new Promise((resolve, reject) => {
                                const userPayload = {
                                  community_id:
                                    communityID?.data?.insert_brd_communities
                                      ?.returning?.[0]?.id,
                                  user_id: item,
                                };
                                const addUsers = async () => {
                                  await insertCommunitieUser({
                                    variables: { data: userPayload },
                                  });
                                };
                                updateProgress();
                                addUsers()
                                  .then(resolve)
                                  .catch((error) => {});
                              });
                            })
                          )
                            .then(resolve)
                            .catch((error) => {});
                        };

                        const updateData = async (communityId: any) => {
                          await Promise.all(
                            uniqueUserIDs?.map((item: any) => {
                              return new Promise((resolve, reject) => {
                                client
                                  .subscribe({
                                    query: GET_COMMUNTITIES_USER,
                                    variables: {
                                      condition: {
                                        _and: [
                                          {
                                            community_id: { _eq: communityId },
                                          },
                                          { user_id: { _eq: item } },
                                        ],
                                      },
                                    },
                                  })
                                  .subscribe({
                                    next(user: any) {
                                      const userPayload = {
                                        community_id: communityId,
                                        user_id: item,
                                      };

                                      const addUsers = async () => {
                                        await insertCommunitieUser({
                                          variables: { data: userPayload },
                                        });
                                      };

                                      if (
                                        user?.data?.brd_community_users &&
                                        user?.data?.brd_community_users
                                          ?.length < 1
                                      ) {
                                        updateProgress();
                                        addUsers()
                                          .then(resolve)
                                          .catch((error) => {});
                                      } else {
                                        updateProgress();
                                        resolve(1);
                                      }
                                    },
                                  });
                              });
                            })
                          )
                            .then(resolve)
                            .catch((error) => {});
                        };

                        if (
                          comunity?.data?.brd_communities &&
                          comunity?.data?.brd_communities?.length < 1
                        ) {
                          addConv();
                        } else {
                          updateData(comunity?.data?.brd_communities?.[0]?.id);
                        }
                      },
                    });
                },
              });
          });
        })
      )
        .then(() => {
          toast.success("Communities Generated Successfully");
          setLoading(false);
          setProgress(0);
        })
        .catch((error) => {
          setLoading(false);
          setProgress(0);
        });
    }
  };

  return (
    <>
      <div
        className={`absolute top-0 w-full z-10 bg-white dark:bg-dark-secondary transition-all ease-in-out duration-300 h-full flex flex-col ${
          showCommunityBar ? "left-0" : "left-[-800px]"
        }`}
      >
        <div
          className={` px-[23px] text-white text-[19px] pt-[60px] bg-whatsappBasic`}
        >
          <div className="flex items-center mb-5">
            <ArrowBackOutlinedIcon
              onClick={() => {
                setShowCommunityBar(false);
              }}
              sx={{ cursor: "pointer" }}
            />
            <p className="ml-5">Community</p>
          </div>
        </div>
        <SearchBar setSearch={setCommunitySearchTerm} />
        <div className="p-[20px] pb-0 w-full">
          <Autocomplete
            disablePortal
            onChange={(_, data: any) => {
              setConversations([]);
              setOffset(0);
              setErrorMessage("");
              if (data?.id) {
                setBrandIds([data?.id]);
              } else {
                setBrandIds(brd_ids);
              }
            }}
            options={brands ? brands : []}
            getOptionLabel={(option: any) => option?.name}
            renderOption={(props, option: any) => (
              <Box component="li" {...props}>
                {option?.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField color="success" label="Brand" {...params} />
            )}
            noOptionsText=""
          />
        </div>

        <div className="text-primary dark:text-dark-primary overflow-scroll flex-grow">
          <div className="p-[20px] pb-0 w-full">
            <Button
              disabled={loading}
              onClick={reGenerateComu}
              fullWidth
              variant="outlined"
            >
              {loading ? "Generating..." : "Re-Generate Communities"}
            </Button>
            {loading && (
              <div className="text-[14px] mt-1 flex gap-3 items-center w-full">
                <LinearProgress
                  sx={{ width: "100%" }}
                  variant="determinate"
                  value={progress}
                />
                <p>{progress}%</p>
              </div>
            )}
          </div>
          {defaultRole === "admin" && (
            <div className="p-[20px] w-full">
              <CsvComp brdId={brandIDs} />
            </div>
          )}
          {conversations?.length < 1 && !errorMessage ? (
            <>
              <ChatRowSekeleton />
              <ChatRowSekeleton />
              <ChatRowSekeleton />
              <ChatRowSekeleton />
              <ChatRowSekeleton />
              <ChatRowSekeleton />
              <ChatRowSekeleton />
              <ChatRowSekeleton />
            </>
          ) : (
            conversations?.map((conversation: any) => {
              return <ChatRow conversation={conversation} label={true} />;
            })
          )}
          {errorMessage && conversations?.length < 1 && (
            <div className="flex items-center justify-center gap-3 mt-12">
              <ErrorIcon color="error" />
              <p className="font-bold">{errorMessage}</p>
            </div>
          )}
          {!communitySearchTerm && conversations?.length > 0 && (
            <div className="flex py-3 justify-center items-center bg-whatsappSecondaryBg dark:bg-dark-secondary">
              <ExpandMoreIcon
                onClick={() => setOffset(offset + 10)}
                sx={{ cursor: "pointer" }}
                color="primary"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CommunityComp;
