import { gql } from "@apollo/client";

export const GET_LINKEDIN_ORGANIZATION_INFO = gql`
  query MyQuery($access_token: String!) {
    get_linkedin_u_organization_info(arg1: { access_token: $access_token }) {
      elements {
        organization
      }
    }
  }
`;
