import { gql } from "@apollo/client";

export const ADD_SUGGESTION = gql`
  mutation MyMutation($suggestions: [inq_suggestion_insert_input!]!) {
    insert_inq_suggestion(objects: $suggestions) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const ADD_INQ = gql`
  mutation MyMutation($inq: [inq_list_insert_input!]!) {
    insert_inq_list(objects: $inq) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
