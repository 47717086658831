import { gql } from "@apollo/client";

export const EDIT_INQUIRY = gql`
  mutation EditInquiry(
    $id: uuid
    $arrival_datetime: timestamptz
    $departure_datetime: timestamptz
    $flexible_arrival: Boolean
    $flexible_departure: Boolean
    $from: String
    $to: String
    $preferred_airline: String
    $cabin: String
    $payment_method_id: uuid
    $direct_flight: Boolean
    $approx_budget: float8
  ) {
    update_inq_list(
      where: { id: { _eq: $id } }
      _set: {
        arrival_datetime: $arrival_datetime
        departure_datetime: $departure_datetime
        flexible_arrival: $flexible_arrival
        flexible_departure: $flexible_departure
        from: $from
        to: $to
        preferred_airline: $preferred_airline
        cabin_class: $cabin
        payment_method_id: $payment_method_id
        direct_flight: $direct_flight
        approx_budget: $approx_budget
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const INSERT_INQUIRY = gql`
  mutation InsertInquiry($data: inq_list_insert_input!) {
    insert_inq_list_one(object: $data) {
      id
    }
  }
`;
