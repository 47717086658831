import React from "react";
import CostRow from "./components/CostRow";

export default function PassengersCost({
  passengers,
  errors,
  register,
  control,
  setValue,
  watch,
}: any) {
  return (
    <div>
      <div className="bg-gray-100 dark:bg-[#28243D] rounded-lg p-2 mt-4">
        <div className="flex flex-col border-b border-gray-300 w-full">
          {/* Section Heading */}
          <h1 className="flex-1 w-full font-bold text-left text-primary dark:text-dark-primary">Passenger Cost</h1>
        </div>
        <div className="mt-2">
          {passengers.map((passenger: any, index: any) => {
            return (
              <>
                <CostRow
                  passenger={passenger}
                  register={register}
                  errors={errors}
                  index={index}
                  control={control}
                  setValue={setValue}
                  watch={watch}
                />
                <hr className="border-gray-300  border-dashed my-2" />
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}
