import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import {
  INSERT_BRD_SOCIAL_AUTHENTICATION,
  UPDATE_BRD_SOCIAL_AUTHENTICATION,
} from "~/graphql/brd_social_authentication/Mutation";
import SocialAccountsTable from "./SocialAccountsTable";
import { GET_LINKEDIN_USER_INFO } from "~/graphql/get_linkedin_u_info/Query";
import { GET_LINKEDIN_ORGANIZATION_NAMES } from "~/graphql/get_linkedin_u_organization_names/Query";
import { GET_LINKEDIN_ORGANIZATION_INFO } from "~/graphql/get_linkedin_u_organization_info/Query";
import { useUserDefaultRole } from "@nhost/react";
import VanityNamesDialog from "./VanityNamesDialog";

const ProfileConnectSocial = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("accesstoken");
  const profileId = searchParams.get("profileId");
  const platform = searchParams.get("platform");
  const accessToken_type = searchParams.get("accessToken_type");
  const type = searchParams.get("type");
  const navigate = useNavigate();
  const { brd_id } = useParams();
  const role = useUserDefaultRole();

  const [stableToken, setStableToken] = useState<string | null>(null);
  const [organizationIds, setOrganizationIds] = useState<string[]>([]);
  const [vanityNames, setVanityNames] = useState<{ [id: string]: string }>({});
  const [userName, setUserName] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Store the token once available
  useEffect(() => {
    if (token) setStableToken(token);
  }, [token]);

  // Fetch LinkedIn user info
  const { data: userInfo } = useQuery(GET_LINKEDIN_USER_INFO, {
    variables: { access_token: stableToken },
    skip: !stableToken,
  });

  // Fetch LinkedIn organization info (to get organization IDs)
  const { data: orgInfo } = useQuery(GET_LINKEDIN_ORGANIZATION_INFO, {
    variables: { access_token: stableToken },
    skip: !stableToken,
  });

  // Save organization IDs from orgInfo
  useEffect(() => {
    if (orgInfo?.get_linkedin_u_organization_info?.elements) {
      const organizations =
        orgInfo.get_linkedin_u_organization_info.elements.map((org: any) =>
          org.organization.replace("urn:li:organization:", "")
        );
      setOrganizationIds(organizations);
    }
  }, [orgInfo]);

  // Lazy query to fetch vanity names of organizations
  const [fetchVanityName] = useLazyQuery(GET_LINKEDIN_ORGANIZATION_NAMES);

  // Fetch vanity names for organizations
  useEffect(() => {
    if (stableToken && organizationIds.length > 0) {
      organizationIds.forEach((id) => {
        fetchVanityName({
          variables: {
            access_token: stableToken,
            id: id,
          },
        }).then((result) => {
          console.log(
            "adfasdfasdf",
            result?.data?.get_linkedin_u_organization_names?.name?.localized
              ?.en_US
          );
          const vanityName =
            result?.data?.get_linkedin_u_organization_names?.name?.localized
              ?.en_US;
          setVanityNames((prev) => ({ ...prev, [id]: vanityName }));
        });
      });
    }
    console.log("Vanity Names:", vanityNames, stableToken);
  }, [stableToken, organizationIds, fetchVanityName]);

  // Open dialog when vanityNames are updated
  useEffect(() => {
    if (vanityNames && Object.keys(vanityNames).length > 0) {
      setIsDialogOpen(true);
    }
  }, [vanityNames]);

  // Extract user information from LinkedIn
  useEffect(() => {
    if (userInfo) {
      const fullName = `${
        userInfo?.get_linkedin_u_info?.firstName?.localized?.en_US ?? ""
      } ${userInfo?.get_linkedin_u_info?.lastName?.localized?.en_US ?? ""}`;
      const profilePictureURL =
        userInfo?.get_linkedin_u_info?.profilePicture?.["displayImage~"]
          ?.elements?.[0]?.identifiers?.[0]?.identifier;
      setUserName(fullName);
      setProfileUrl(profilePictureURL);
    }
  }, [userInfo]);

  const [insertToken] = useMutation(INSERT_BRD_SOCIAL_AUTHENTICATION);
  const [updateToken] = useMutation(UPDATE_BRD_SOCIAL_AUTHENTICATION);

  // Handle token insertion and update
  useEffect(() => {
    if (
      stableToken &&
      profileId &&
      userName &&
      profileUrl &&
      type === "profileLogin"
    ) {
      updateToken({
        variables: {
          profile_id: profileId,
          brd_id: brd_id,
          data: { access_token: stableToken },
        },
      }).then((data: any) => {
        if (data?.data?.update_brd_social_authentication?.affected_rows) {
          toast.success("Token Updated Successfully");
        } else {
          insertToken({
            variables: {
              data: {
                profile_id: profileId,
                access_token: stableToken,
                platform: platform,
                accessToken_type: accessToken_type,
                brd_id: brd_id,
                profile_picture: profileUrl,
                user_name: userName,
              },
            },
          }).then((res: any) => {
            if (res?.data?.insert_brd_social_authentication?.affected_rows) {
              toast.success("LinkedIn Connected Successfully");
            }
          });
        }
      });

      navigate(`/${role}/brands/${brd_id}/social-connect`);
    }
  }, [stableToken, profileId, userName, profileUrl]);

  useEffect(() => {
    if (type === "OrganizationLogin" && stableToken) {
      navigate(`/${role}/brands/${brd_id}/social-connect`);
    }
  }, [stableToken]);

  return (
    <div className="p-5 pt-10 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-3xl text-basic mb-6">
        Manage Social Accounts
      </h1>
      <SocialAccountsTable />
      {type === "Page" && (
        <VanityNamesDialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          vanityNames={vanityNames}
          stableToken={stableToken}
        />
      )}
    </div>
  );
};

export default ProfileConnectSocial;
