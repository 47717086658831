import React from "react";
// Import components
import InputField from "components/FormInputs/InputField";
import SelectBox from "components/FormInputs/SelectBox";
// Import intial data
import { legDefaultValue } from "../../../../../../../../../../../../data/InitalDefaultValue";
import { CabinClasses } from "~/data/cabinClasses";

export default function FlightType({
  register,
  errors,
  control,
  setValue,
  unregister,
  getValues,
}: any) {
  return (
    <div>
      <div className="flex w-full bg-gray-100 dark:bg-[#28243D] rounded-lg p-2 flex-col">

        <div className="flex items-center">
          {/* Trip Type Type */}
          <h1 className="text-primary dark:text-dark-primary text-left">
            Trip Type:
          </h1>
          <div className="flex-1 self-stretch flex flex-row items-center justify-start gap-[30px] pl-10">
            <div
              className="flex flex-row items-center justify-center gap-[5px]"
              onClick={() => {
                setValue("returnLeg", legDefaultValue);
              }}
            >
              <InputField
                label="One Way"
                register={register}
                type="radio"
                errors={errors}
                required={true}
                defaultValue={1}
                fieldName="tripType"
                checked={getValues("tripType") === 1 ? true : false}
                containerClasses="flex"
                labelClasses="mb-0"
              />
            </div>
            <div className="flex flex-row items-center justify-center gap-[5px]">
              <InputField
                label="Round Trip"
                register={register}
                type="radio"
                errors={errors}
                required={true}
                defaultValue={2}
                fieldName="tripType"
                checked={getValues("tripType") === 2 ? true : false}
                containerClasses="flex"
                labelClasses="mb-0"
              />
            </div>
          </div>
          {/* Cabin Class Select box*/}
          <div className="flex-1">
            <SelectBox
              errors={errors}
              control={control}
              fieldName={"cabinType"}
              label="Cabin Class"
              options={CabinClasses}
              selectedOption={CabinClasses[2]}
              required={true}
              optionContainerClasses="dark:bg-dark-secondary"
              inputClasses="border rounded border pl-3 focus:outline-none focus:border-basic dark:text-dark-primary dark:bg-dark-primary font-normal text-primary"
              labelClasses="text-primary dark:text-dark-primary text-base"
              checkIconClasses="text-basic"
              optionClasses="text-primary dark:text-dark-primary"
              optionHoverClasses="bg-primary dark:bg-dark-primary"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
