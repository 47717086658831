import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

const WhatsAppLogIn: React.FC = () => {
  const { brd_id }: any = useParams();

  const [searchParams] = useSearchParams();
  const status: any = searchParams.get("status");

  useEffect(() => {
    if (status === true) {
      toast.success("Whatsapp Authenticated Successfully");
    } else if (status === true) {
      toast.error("Whatsapp Not Authenticated! Try Again");
    }
  }, [status]);

  const handleFBLogin = () => {
    const currentURL = window.location.href; // This will include the entire URL including path

    const serverlessFunctionURL =
      "https://local.flygency.com/authenticateWhatsapp";

    window.location.href = `${serverlessFunctionURL}?origin=${encodeURIComponent(
      currentURL
    )}&brdId=${encodeURIComponent(brd_id)}`;
  };

  return (
    <div className="mb-4 sm:p-6 bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary flex-col rounded-lg shadow">
      <div className="flex justify-between items-center">
        <div>
          <h3 className="mb-2 text-xl font-semibold">WhatsApp API</h3>
          <p>Connect Your WhatsApp Data</p>
        </div>
        <div>
          <Button variant="outlined" onClick={handleFBLogin}>
            Connect
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WhatsAppLogIn;
