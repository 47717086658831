import DistinationLine from "../../assets/svg/DistinationLine";
import moment from "moment";
import TickBox from "../../assets/svg/TickBox";
import Money from "../../assets/svg/Money";
import AirPlane from "../../assets/svg/AirPlane";
import Edit from "../../assets/img/edit.png";
import testEdit from "../../assets/img/Vector.png"
import { useState } from "react";
import InquiryCardHead from "./components/InquiryCardHead";
import PassengerRecord from "./components/PassengerRecord";
import InquiryCardEdit from "./components/InquiryCardEdit";
import { getCityName } from "~/utils/utilsFunctions";
import From from "../../assets/img/From-light.svg"
import To from "../../assets/img/To-light.svg"
import FromDark from "../../assets/img/From-dark.svg"
import ToDark from "../../assets/img/To-dark.svg"
import { BiSolidMessageSquareEdit } from "react-icons/bi";
import { useEffect } from "react";
import SideDrawer from "~/components/common/sideDrawer";

const InquiryCard = ({ inquiry, header, height, editAble }: any) => {
  const [edit, setEdit] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);

    const handleStorageChange = () => {
      const updatedData = localStorage.getItem("darkMode");
      setDarkMode(updatedData ? JSON.parse(updatedData) : false);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <div
      className={`flex-1 w-full rounded-2xl shadow bg-secondary dark:bg-dark-secondary text-primary dark:to-dark-primary shadow min-h-[${height}] flex flex-col pt-0 px-0 box-border items-end justify-between text-left text-xs
    `}
    >
      <InquiryCardHead inquiry={inquiry} show={header} />

      {editAble && (
        // <img
        //   onClick={() => {
        //     setEdit(!edit);
        //   }}
        //   className="cursor-pointer w-[15px] h-[14.94px] m-2 absolute text-basic"
        //   alt=""
        //   src={testEdit}
        // />
        <BiSolidMessageSquareEdit
          className="cursor-pointer text-basic dark:text-dark-primary m-3.5 w-5 h-5 absolute"
          onClick={() => {
            setEdit(!edit);
          }}
        />
      )}
      <div className="w-full text-primary dark:text-dark-primary flex flex-col gap-3.5 justify-between rounded-2xl h-full">
        <div className="self-stretch flex flex-row pb-2.5 pt-10 px-[30px] mt-5 items-center justify-between">
          <div className="flex flex-col items-start justify-start gap-[1px]">
            {/* <div className="self-stretch relative text-3xs ">From</div> */}
            {darkMode ? <img src={FromDark} alt="" /> : <img src={From} alt="" />}
            <div className="self-stretch relative text-7xl font-medium text-basic dark:text-dark-primary">
              {inquiry?.from}
            </div>
            <div className="self-stretch relative font-medium text-basic dark:text-dark-primary">
              {getCityName(inquiry?.from)}
            </div>

          </div>
          <div className="w-full">
            {/* <DistinationLine /> */}
          </div>
          <div className="flex flex-col items-end justify-start text-right">
            {/* <div className="self-stretch relative text-3xs ">To</div> */}
            {darkMode ? <img src={ToDark} alt="" /> : <img src={To} alt="" />}
            <div className="relative text-7xl font-medium text-basic dark:text-dark-primary">
              {" "}
              {inquiry.to}
            </div>
            <div className="self-stretch relative text-basic dark:text-dark-primary font-medium">
              {inquiry.trip_type === "oneWay" ? (
                <span>-</span>
              ) : (
                getCityName(inquiry?.to)
              )}
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-1 md:flex-row px-[30px] py-3.5 items-center justify-between bg-basic rounded-b-2xl">
          <div className="flex flex-col items-start justify-center w-full">
            <div className="flex flex-col items-start justify-start gap-1 text-white font-medium">
              <div className="self-stretch relative">
                {" "}
                {moment(inquiry.departure_datetime).format("DD-MM-YYYY")}
              </div>
              <div className="flex flex-row items-center justify-center gap-[6px]">
                {inquiry.flexible_departure ? (
                  <TickBox />
                ) : (
                  <div className="relative box-border w-[11px] h-[11px] border-[1px] border-solid border-darkgray"></div>
                )}
                <div className="relative">Flexible</div>
              </div>
              {/* <div className="hidden md:inline-block">
                  <AirPlane />
                </div> */}
              <div className="relative inline-block shrink-0">
                <span>{`Preferred Airline: `}</span>
                <span className="font-bold">{inquiry.preferred_airline}</span>
              </div>
              <div className="flex flex-row items-center justify-start gap-1 mt-2">
                {/* <Money /> */}
                <div className="relative inline-block shrink-0">
                  <span>Approx Budget:</span>
                  <b>{inquiry?.approx_budget}</b>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-end justify-center w-full">
            <div className="flex flex-col items-end justify-start gap-1 text-white font-medium">
              <div className="self-stretch relative text-end">
                {inquiry.trip_type === "oneWay" ? (
                  <span>-</span>
                ) : (
                  moment(inquiry.arrival_datetime).format("DD-MM-YYYY")
                )}
              </div>
              <div className="flex flex-row items-center justify-center gap-[6px]">
                {inquiry.flexible_arrival ? (
                  <TickBox />
                ) : (
                  <div className="relative box-border w-[11px] h-[11px] border-[1px] border-solid border-darkgray"></div>
                )}
                <div className="relative">Flexible</div>
              </div>
              {/* <div className="hidden md:inline-block">
                  <AirPlane />
                </div> */}
              <div className="flex justify-end gap-1">
                <span>{`Direct Flight: `}</span>
                <span className="">
                  {inquiry.direct_flight ? "Yes" : "No"}
                </span>
              </div>
              <div className="mt-2">
                <PassengerRecord inq_passengers={inquiry?.inq_passengers} />
              </div>
            </div>
          </div>
          {/* <div className="flex flex-col items-end justify-start w-full bg-blue-500">
              <div className="flex flex-row items-start justify-start gap-1 text-white font-medium bg-red-500">
                <img
                  className="relative w-auto h-[13px] w-[100px]"
                  alt=""
                  src="/vector17.svg"
                />
                <div className="relative inline-block h-3 shrink-0">
                  <div className="self-stretch relative">
                    {inquiry.trip_type === "oneWay" ? (
                      <span>-</span>
                    ) : (
                      moment(inquiry.arrival_datetime).format("DD-MM-YYYY")
                    )}
                  </div>
                  <div className="flex flex-row items-center justify-center gap-[6px]">
                    {inquiry.flexible_arrival ? (
                      <TickBox />
                    ) : (
                      <div className="relative box-border w-[11px] h-[11px] border-[1px] border-solid border-darkgray"></div>
                    )}
                    <div className="relative">Flexible</div>
                  </div>
                  <span>{`Direct Flight: `}</span>
                  <span className="">
                    {inquiry.direct_flight ? "Yes" : "No"}
                  </span>
                  <PassengerRecord inq_passengers={inquiry?.inq_passengers} />
                </div>
              </div>
            </div> */}
        </div>
      </div>
      <SideDrawer
        setSidePopup={setEdit}
        sidePopup={edit}
        component={
          <InquiryCardEdit inquiry={inquiry} setEdit={setEdit} />
        }
      />
    </div>
  );
};

InquiryCard.defaultProps = {
  header: true,
  height: "300px",
  editAble: false,
};

export default InquiryCard;
