import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import AddressBox from "components/FormInputs/AddressBox";
import InputField from "components/FormInputs/InputField";
import PhoneBox from "components/FormInputs/PhoneBox";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import travelHouseStore from "store/travelHouse/travelHouseStore";
import {
  GET_ALIASES,
  GET_BRANDS_WITH_BRD,
  GET_BRANDS_WITH_THP,
} from "./graphql/Query";
import { ADD_USER } from "./graphql/Mutation";
import BrandsAssign from "./components/BrandsAssign";
import { GET_TRAVEL_HOUSE_BY_ID } from "~/modules/InboxModule/segments/Inbox/graphql/Query";
import useRefetch from "~/hooks/useRealtime";
import useValidation from "~/hooks/useValidation";
import useAssignedBrands from "~/hooks/useAssignedBrands";
import { useEffect, useState } from "react";
import useGmailAliases from "~/hooks/useGmailAliases";
import useThpListDetail from "~/hooks/useThpListDetail";
import { GetCustomUrl } from "~/utils/GetCustomUrl";

export default function UserAdd({ defaultRole, setSidePopup }: any) {
  const [addUsers] = useMutation(ADD_USER);
  const { isLoading, aliases } = useGmailAliases();
  let dataRes: any = null;
  const RESTRCITED_ROLES = ["vendor", "user"];
  const assignedBrands = useAssignedBrands();
  const [data, setData] = useState<any>([]);
  // const { setSidePopup } = commonStore();
  const { travelHouse }: any = travelHouseStore();
  const validation = useValidation();
  // Get Brands
  // const { loading, error, data } = useQuery(GET_BRANDS_WITH_BRD, {
  //   variables: { brands: brands },
  // });
  const [getBrandsMembers] = useLazyQuery(GET_BRANDS_WITH_BRD);
  const [getAllMembers] = useLazyQuery(GET_BRANDS_WITH_THP);

  const customUrl = GetCustomUrl(window.location.host);

  const { thpHouse } = useThpListDetail(customUrl);

  const fetchMembers = async () => {
    if (assignedBrands) {
      dataRes = await getBrandsMembers({
        variables: { brands: assignedBrands },
      });
      setData(dataRes);
    } else {
      dataRes = await getAllMembers({
        variables: { thp_id: travelHouse?.id },
      });

      setData(dataRes);
    }
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  // Appolo Client
  const client = useApolloClient();

  const refetch = useRefetch();
  const { loading: travelHouseLoading, data: travelHouseData } = useQuery(
    GET_TRAVEL_HOUSE_BY_ID,
    {
      variables: { id: travelHouse?.id },
      fetchPolicy: "network-only",
    }
  );

  const {
    data: getAliasData,
    loading: getAliasLoading,
    error: gmailErr,
  } = useQuery(GET_ALIASES, {
    variables: {
      refresh_token: travelHouseData?.thp_list[0]?.email_refresh_token,
    },
    skip: !travelHouseData?.thp_list[0]?.email_refresh_token,
  });
  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
    control,
    setValue,
  } = useForm();

  const onSubmit = async (formData: any) => {
    // check if user have no brands
    // return 0;

    // if (formData?.brands?.length <= 0) {
    //   toast.error("At least one brand is required for update!");
    //   return 0;
    // }

    if (!formData?.brands) {
      toast.error("At least one brand is required for update!");
      return 0;
    }
    let brd_users: any = [];
    if (typeof formData.brands === "string") {
      brd_users.push({
        thp_id: travelHouse?.id,
        brd_id: formData.brands,
      });
    } else {
      formData.brands.forEach((brd_id: any) => {
        brd_users.push({
          thp_id: travelHouse?.id,
          brd_id: brd_id,
        });
      });
    }

    const resPhone = await validation.checkPhone(formData.phoneNumber);

    if (resPhone?.length > 0) {
      toast.error("Phone number already exist");
      return 0;
    }

    const resEmail = await validation.checkEmail(formData.email);
    if (resEmail.length > 0) {
      toast.error("Email already exist");
      return 0;
    }

    try {
      const res = await addUsers({
        variables: {
          user: {
            displayName: formData.displayName,
            email: formData.email,
            defaultRole: defaultRole === "team" ? formData.role : defaultRole,
            phoneNumber: formData.phoneNumber,
            metadata: {
              country: formData.location.country,
              addressLine1: formData.location.addressLine1,
              addressLine2: formData.location.addressLine2,
              city: formData.location.city,
              postalCode: formData.location.postalCode,
              region: formData.location.region,
              alias: formData.alias,
            },
            brd_users: {
              data: brd_users,
            },
            thp_users_profile: {
              data: {
                basic_salary: formData?.basic_salary,
                bio: formData?.bio,
                pseudo_name: formData?.pseudo_name,
                twitter: formData?.twitter,
                facebook: formData?.facebook,
                linkedin: formData?.linkedin,
                discord: formData?.discord,
                x: formData?.x,
                skype: formData?.skype,
                youtube: formData?.youtube,
                instagram: formData?.instagram,
                commission: formData?.commission,
              },
            },
            locale: "en",
          },
        },
      });
      if (res.data?.insertUsers?.returning?.length > 0) {
        toast.success("User added successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reset();
        await refetch();
        setSidePopup(false);
      }
    } catch (e: any) {
      // toast.error("e", e);
    }
  };

  // return loading while loading data
  // if (isLoading) return <div>Loading...</div>;
  // Return error if get gql error
  // if (error) return <div>Error in the query {error.message}</div>;
  // return <p>tset</p>;
  if (Object.keys(data)?.length > 0) {
    return (
      <div className="md:w-[500px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-3xl text-basic ">Add User</h1>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div>
            {defaultRole === "team" && (
              <div>
                <select
                  {...register("role", { required: true })}
                  className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="consultant">Consultant</option>
                  <option value="accountant">Accountant</option>
                  <option value="admin">Admin</option>
                  <option value="editor">Editor</option>
                </select>
                {/* <InputField
                register={register}
                errors={errors}
                fieldName="alias"
                required={true}
                placeHolder="Email Alias"
              /> */}
                {isLoading ? (
                  "Loading..."
                ) : (
                  <select
                    {...register("alias")}
                    className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="">Select Alias</option>
                    {aliases?.map((email: any) => (
                      <option key={email.sendAsEmail} value={email.sendAsEmail}>
                        {email.sendAsEmail}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            )}
            <div className="mt-2 mb-2">
              <InputField
                register={register}
                errors={errors}
                fieldName="displayName"
                required={true}
                className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeHolder="User Name"
              />
            </div>
            {RESTRCITED_ROLES.indexOf(defaultRole) < 0 && (
              <div className="mt-2 mb-2">
                <InputField
                  register={register}
                  errors={errors}
                  fieldName="pseudo_name"
                  required={true}
                  className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeHolder="Pseudo Name"
                />
              </div>
            )}
            <div className="mt-2 mb-2">
              <InputField
                type="email"
                register={register}
                errors={errors}
                fieldName="email"
                required={true}
                className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeHolder="User Email"
              />
            </div>
            <div className="mt-2 mb-2">
              <PhoneBox
                control={control}
                errors={errors}
                fieldName="phoneNumber"
                setValue={setValue}
              />
            </div>
            <div className="mt-2 mb-2">
              <AddressBox
                register={register}
                errors={errors}
                fieldName="location"
                setValue={setValue}
              />
            </div>
            {RESTRCITED_ROLES.indexOf(defaultRole) < 0 && (
              <>
                <div className="mt-2 mb-2">
                  <textarea
                    {...register("bio")}
                    id="bio"
                    placeholder="Bio, Tell about yourself"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  ></textarea>
                </div>
                <div className="mt-2 mb-2">
                  <InputField
                    type="number"
                    register={register}
                    errors={errors}
                    fieldName="basic_salary"
                    required={true}
                    placeHolder="Monthly Basic Salary"
                    className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div className="mt-2 mb-2">
                  <InputField
                    type="number"
                    register={register}
                    errors={errors}
                    fieldName="commission"
                    placeHolder="Commission"
                    className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div className="mt-2 mb-2">
                  <InputField
                    register={register}
                    errors={errors}
                    fieldName="facebook"
                    placeHolder="Facebook"
                    className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div className="mt-2 mb-2">
                  <InputField
                    register={register}
                    errors={errors}
                    fieldName="intagram"
                    placeHolder="Intagram"
                    className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div className="mt-2 mb-2">
                  <InputField
                    register={register}
                    errors={errors}
                    fieldName="linkedIn"
                    placeHolder="LinkedIn"
                    className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div className="mt-2 mb-2">
                  <InputField
                    register={register}
                    errors={errors}
                    fieldName="skype"
                    placeHolder="Skype"
                    className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div className="mt-2 mb-2">
                  <InputField
                    register={register}
                    errors={errors}
                    fieldName="discord"
                    placeHolder="discord"
                    className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div className="mt-2 mb-2">
                  <InputField
                    register={register}
                    errors={errors}
                    fieldName="x"
                    placeHolder="X"
                    className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </>
            )}
          </div>
          {/* Assign Brands */}
          <BrandsAssign brd_list={data?.data?.brd_list} register={register} />

          {(thpHouse?.[0]?.yearly === false &&
            thpHouse?.[0]?.brd_users?.filter(
              (item: any) => item?.users?.defaultRole !== "user"
            ).length <
              thpHouse?.[0]?.def_subscription_plan?.monthly_plan_seats) ||
          (thpHouse?.[0]?.yearly === true &&
            thpHouse?.[0]?.brd_users?.filter(
              (item: any) => item?.users?.defaultRole !== "user"
            ).length <
              thpHouse?.[0]?.def_subscription_plan?.yearly_plan_seats) ? (
            <div className="flex gap-2 w-full">
              <button
                className="w-full btn bg-green-500 text-white rounded-md p-2"
                type="submit"
              >
                Save
              </button>
            </div>
          ) : (
            <p className="text-red-500 font-bold mt-6 text-center">
              You have used all your seat credits
            </p>
          )}
        </form>
      </div>
    );
  } else {
    return <p>Loading...</p>;
  }
}
