import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { QuillToolbar, modules, formats } from "./components/CustomToolbar";
import { useRef } from "react";

const ContentEditor = ({
  content,
  setContent,
  height = "400px",
  id = "t1",
}: any) => {
  const quillRef = useRef<any>(null);
  return (
    <>
      <QuillToolbar toolbarId={id} quillRef={quillRef} />
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={content ? content : ""}
        onChange={setContent}
        modules={modules(id)}
        formats={formats}
        className={`bg-primary pt-5 dark:bg-dark-primary text-primary dark:text-dark-primary h-[${height}]`}
      />
    </>
  );
};

export default ContentEditor;
