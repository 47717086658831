import React, { useEffect, useState } from "react";
import SidePopup from "~/components/common/SidePopup";
import Detail from "./components/Detail";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { GET_ADS } from "./graphql/Query";
import { useQuery } from "@apollo/client";
import { GET_ASSIGNED_BRANDS } from "~/modules/DashboardModule/segments/DashboardStats/graphql/Query";
import { useUserId } from "@nhost/react";
import Add from "./components/Add/Add";

export default function GoogleAds() {
  const { travelHouse }: any = travelHouseStore();
  const userId = useUserId();
  const {
    loading: bL,
    data,
    error: bE,
  } = useQuery(GET_ASSIGNED_BRANDS, {
    variables: {
      id: userId,
      thp_id: travelHouse?.id,
    },
  });

  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const brdRec: any = [];
    if (data) {
      if (data?.brd_users?.length > 0) {
        data?.brd_users?.map((brd: any) => {
          brdRec.push(brd?.brd_id);
        });
      } else {
        data?.thp_list?.[0].brd_lists?.map((brd: any) => {
          brdRec.push(brd?.id);
        });
      }
      setBrands(brdRec);
    }
  }, [bL]);

  const {
    loading,
    data: adData,
    error,
  } = useQuery(GET_ADS, {
    variables: { brd_ids: brands },
    skip: !brands?.[0],
  });

  if (bL) return <div>Loading...</div>;
  if (bE) return <div>Error in the query {bE.message}</div>;

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <>
      <h1 className="text-primary dark:text-dark-primary uppercase text-[18px] font-bold border-b-2 divide-gray-200  dark:divide-gray-700 mb-2">
        Google Ads
      </h1>
      {adData?.acc_google_ads && adData?.acc_google_ads.length > 1 && (
        <Detail data={adData?.acc_google_ads} />
      )}
      <SidePopup component={<Add />} text="Add Account" />
    </>
  );
}
