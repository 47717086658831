import { Box, Button, TextField, InputAdornment } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ALLOWED_IMAGE_FORMATS } from "~/config/constants";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { getAiData } from "~/utils/getAiData";
import { typingEffect } from "~/utils/typingEffect";

const BrandPgSetHeroSec = ({
  page,
  addHeroSecDetails,
  register,
  errors,
  handleSubmit,
  setLogoFile,
}: any) => {
  const [pageHeadingVal, setPageHeadingVal] = useState<any>(
    page?.page_heading || ""
  );
  const [pageSubHeadVal, setSubPageHeadVal] = useState<any>(
    page?.page_sub_heading || ""
  );

  const [pageSubHeadVal2, setSubPageHeadVal2] = useState<any>(
    page?.page_sub_heading_2 || ""
  );

  const [footerAnchText, setFooterAnchText] = useState<any>(
    page?.footer_anchor_descriptive_text || ""
  );

  const handleLogoUpload = async (event: any) => {
    const file = event.target.files[0];

    if (
      !ALLOWED_IMAGE_FORMATS.includes(file.type.split("/").at(1).toLowerCase())
    ) {
      toast.error("Invalid File format");
    } else {
      setLogoFile({ file });
    }
  };

  const onSubmit = async (inpData: any) => {
    addHeroSecDetails(inpData);
  };

  return (
    <>
      <div className="bg-secondary dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
        <h1 className="text-xl">Hero Section</h1>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
            <TextField
              error={errors["page_heading"] ? true : false}
              {...register("page_heading", { required: true })}
              label="Main Heading"
              type="text"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(
                          "Write an seo optimized Page Heading For Travelling Website 3 to 5 Words: unique and without quotes"
                        );
                        await typingEffect(setPageHeadingVal, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={pageHeadingVal}
              onChange={(e: any) => setPageHeadingVal(e.target.value)}
            />
            <TextField
              error={errors["page_sub_heading"] ? true : false}
              {...register("page_sub_heading", { required: true })}
              label="Sub Heading"
              type="text"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(
                          `Write an seo optimized Page Sub Heading For ${
                            pageHeadingVal || "Travelling Website"
                          } 6 to 8 Words: unique and without quotes `
                        );
                        await typingEffect(setSubPageHeadVal, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={pageSubHeadVal}
              onChange={(e: any) => setSubPageHeadVal(e.target.value)}
            />
            <TextField
              error={errors["page_sub_heading_2"] ? true : false}
              {...register("page_sub_heading_2", { required: true })}
              label="Description"
              type="text"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(
                          `Write an seo optimized Page short description For ${
                            pageHeadingVal || "Travelling Website"
                          } 1 to 2 lines: unique and without quotes `
                        );
                        await typingEffect(setSubPageHeadVal2, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={pageSubHeadVal2}
              onChange={(e: any) => setSubPageHeadVal2(e.target.value)}
            />
            <TextField
              defaultValue={page?.footer_anchor_descriptive_text}
              error={errors["footer_anchor_descriptive_text"] ? true : false}
              {...register("footer_anchor_descriptive_text", {
                required: true,
              })}
              label="Footer Anchor Descriptive Text"
              type="text"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(
                          `Write an seo optimized Page footer anchor sescriptive text For ${
                            pageHeadingVal || "Travelling Website"
                          } 8 to 10 words: unique and without quotes `
                        );
                        await typingEffect(setFooterAnchText, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={footerAnchText}
              onChange={(e: any) => setFooterAnchText(e.target.value)}
            />
            <div>
              <label className="mb-2">Featured Image</label>
              <TextField
                sx={{ width: "100%" }}
                // {...register("featuredImage")}
                type="file"
                inputProps={{
                  style: {
                    paddingTop: "7px",
                    paddingBottom: "27px",
                    paddingLeft: "23px",
                  },
                }}
                variant="outlined"
                onChange={handleLogoUpload}
                helperText="SVG, PNG, JPG or GIF (MAX. 800x400px)"
              />
            </div>
          </div>
          <Button type="submit" variant="contained" sx={{ marginTop: "25px" }}>
            Save Hero Section
          </Button>
        </Box>
      </div>
    </>
  );
};

export default BrandPgSetHeroSec;
