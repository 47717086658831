import ReceiptIcon from "@mui/icons-material/Receipt";
import Person2Icon from "@mui/icons-material/Person2";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import useThpListDetail from "~/hooks/useThpListDetail";
import { GetCustomUrl } from "~/utils/GetCustomUrl";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Link } from "react-router-dom";
import { useUserDefaultRole } from "@nhost/react";
import { useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import AddCredits from "./components/AddCredits";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  outline: "3px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  gap: "30px",
};

const RemainingBalance = ({ type, curPlan, defPaymentMeth }: any) => {
  const [openMod, setOpenMod] = useState<any>(false);

  const role = useUserDefaultRole();

  const customUrl = GetCustomUrl(window.location.host);

  const { thpHouse } = useThpListDetail(customUrl);

  return (
    <div className="flex items-center gap-8 mb-5">
      <div className="rounded-lg text-primary dark:text-dark-primary bg-secondary dark:bg-dark-secondary shadow w-full">
        <div className="flex items-center justify-between w-full border-b px-5 py-4">
          <div className="bg-basic p-2 rounded-md">
            <ReceiptIcon sx={{ color: "white", fontSize: "30px" }} />
          </div>
          <div>
            <p className="font-[400] text-[16px]">Total Invoices</p>
            <div className="flex items-center gap-1 justify-end">
              <p className="text-right text-[25px] font-bold text-black">
                {thpHouse?.[0]?.yearly === true
                  ? thpHouse?.[0]?.def_subscription_plan?.yearly_plan_invoices
                  : thpHouse?.[0]?.def_subscription_plan?.monthly_plan_invoices}
              </p>
              {type !== "account" && (
                <Link to={`/${role}/profileAccount`}>
                  <AddBoxIcon sx={{ color: "#00A36C", cursor: "pointer" }} />
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <p className="px-5 py-3 text-[15px] font-[500] flex items-center gap-2">
            <span className="font-bold text-[20px] text-green-600">
              +{thpHouse?.[0]?.invoice_credits}
            </span>
            <span className="mt-[1px]">Remaining Invoices</span>
          </p>
          {type === "account" ? (
            <button
              onClick={() => setOpenMod("Invoice")}
              className="mr-5 px-2 py-1 rounded shadow text-[15px] bg-green-600 text-white font-bold flex items-center gap-2"
            >
              Add credits
            </button>
          ) : (
            <p className="px-5 py-3 text-[15px] font-[500] flex items-center gap-2">
              <span className="font-bold text-[17px] text-red-600">
                -
                {thpHouse?.[0]?.yearly === true
                  ? thpHouse?.[0]?.def_subscription_plan?.yearly_plan_invoices -
                    thpHouse?.[0]?.invoice_credits
                  : thpHouse?.[0]?.def_subscription_plan
                      ?.monthly_plan_invoices - thpHouse?.[0]?.invoice_credits}
              </span>
              <span>Used</span>
            </p>
          )}
        </div>
      </div>
      <div className="rounded-lg text-primary dark:text-dark-primary bg-secondary dark:bg-dark-secondary shadow w-full">
        <div className="flex items-center justify-between w-full border-b px-5 py-4">
          <div className="bg-basic p-2 rounded-md">
            <Person2Icon sx={{ color: "white", fontSize: "30px" }} />
          </div>
          <div>
            <p className="font-[400] text-[16px]">Total Seats</p>
            <div className="flex items-center gap-1 justify-end">
              <p className="text-right text-[25px] font-bold text-black">
                {thpHouse?.[0]?.yearly === true
                  ? thpHouse?.[0]?.def_subscription_plan?.yearly_plan_seats
                  : thpHouse?.[0]?.def_subscription_plan?.monthly_plan_seats}
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <p className="px-5 py-3 text-[15px] font-[500] flex items-center gap-2">
            <span className="font-bold text-[20px] text-green-600">
              +
              {thpHouse?.[0]?.yearly === true
                ? thpHouse?.[0]?.def_subscription_plan?.yearly_plan_seats -
                  thpHouse?.[0]?.brd_users?.filter(
                    (item: any) => item?.users?.defaultRole !== "user"
                  ).length
                : thpHouse?.[0]?.def_subscription_plan?.monthly_plan_seats -
                  thpHouse?.[0]?.brd_users?.filter(
                    (item: any) => item?.users?.defaultRole !== "user"
                  ).length}
            </span>
            <span className="mt-[1px]">Remaining Seats</span>
          </p>

          <p className="px-5 py-3 text-[15px] font-[500] flex items-center gap-2">
            <span className="font-bold text-[17px] text-red-600">
              -
              {
                thpHouse?.[0]?.brd_users?.filter(
                  (item: any) => item?.users?.defaultRole !== "user"
                ).length
              }
            </span>
            <span>Used</span>
          </p>
        </div>
      </div>
      <div className="rounded-lg text-primary dark:text-dark-primary bg-secondary dark:bg-dark-secondary shadow w-full">
        <div className="flex items-center justify-between w-full border-b px-5 py-4">
          <div className="bg-basic p-2 rounded-md">
            <BrandingWatermarkIcon sx={{ color: "white", fontSize: "30px" }} />
          </div>
          <div>
            <p className="font-[400] text-[16px]">Total Brands</p>
            <div className="flex items-center gap-1 justify-end">
              <p className="text-right text-[25px] font-bold text-black">
                {thpHouse?.[0]?.yearly === true
                  ? thpHouse?.[0]?.def_subscription_plan?.yearly_plan_brands
                  : thpHouse?.[0]?.def_subscription_plan?.monthly_plan_brands}
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <p className="px-5 py-3 text-[15px] font-[500] flex items-center gap-2">
            <span className="font-bold text-[20px] text-green-600">
              +
              {thpHouse?.[0]?.yearly === true
                ? thpHouse?.[0]?.def_subscription_plan?.yearly_plan_brands -
                  thpHouse?.[0]?.brd_lists?.length
                : thpHouse?.[0]?.def_subscription_plan?.monthly_plan_brands -
                  thpHouse?.[0]?.brd_lists?.length}
            </span>
            <span className="mt-[1px]">Remaining Brands</span>
          </p>
          <p className="px-5 py-3 text-[15px] font-[500] flex items-center gap-2">
            <span className="font-bold text-[17px] text-red-600">
              -{thpHouse?.[0]?.brd_lists?.length}
            </span>
            <span>Used</span>
          </p>
        </div>
      </div>
      <Modal open={openMod} onClose={() => setOpenMod(false)}>
        <Box sx={{ ...style, width: "60%" }}>
          <AddCredits
            defPaymentMeth={defPaymentMeth}
            curPlan={curPlan}
            openMod={openMod}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default RemainingBalance;
