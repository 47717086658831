import React from "react";
import InputField from "~/components/FormInputs/InputField";

export default function CostRowAdd({
  passenger,
  register,
  errors,
  setValue,
  index,
  watch,
}: any) {
  setValue(`suggestion_cost_add.${index}.passenger_id`, passenger.id);

  const basicFare = watch(`suggestion_cost_add.${index}.basic_fare`);
  const tax = watch(`suggestion_cost_add.${index}.tax_price`);
  const atol = watch(`suggestion_cost_add.${index}.atol_price`);

  return (
    <div className="flex flex-row gap-4">
      <div className="w-[60%] pt-[30px]">
        {passenger.first_name + " " + passenger.last_name} (
        {passenger.type.toUpperCase()})
      </div>
      {/* Flight Offer Price */}

      <InputField
        label="Basic Fare"
        register={register}
        errors={errors}
        type="number"
        placeHolder="Basic Fare"
        fieldName={`suggestion_cost_add.${index}.basic_fare`}
        required={true}
      />
      {/* Flight Cost Price */}
      <InputField
        label="Tax"
        register={register}
        errors={errors}
        type="number"
        placeHolder="Tax"
        fieldName={`suggestion_cost_add.${index}.tax_price`}
        required={true}
      />
      <InputField
        label="Atol"
        register={register}
        errors={errors}
        type="number"
        placeHolder="Atol"
        fieldName={`suggestion_cost_add.${index}.atol_price`}
        required={true}
      />
      {/* Flight Cost Price */}
      <InputField
        label={`Sale Price ( cost - ${
          (basicFare ? parseFloat(basicFare) : 0) +
          (tax ? parseFloat(tax) : 0) +
          (atol ? parseFloat(atol) : 0)
        })`}
        register={register}
        errors={errors}
        type="number"
        placeHolder="Sale Price"
        fieldName={`suggestion_cost_add.${index}.sale_price`}
        required={true}
      />
    </div>
  );
}
