import { gql } from "@apollo/client";

export const GET_BRAND_DETAIL = gql`
  query GetBrdList($brd_id: uuid) {
    brd_list(where: { id: { _eq: $brd_id } }) {
      gmail_refresh_token
      id
      whatsapp_token
      phoneNumberId
    }
  }
`;
