import {
  Box,
  Button,
  FormLabel,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { getAiData } from "~/utils/getAiData";
import { typingEffect } from "~/utils/typingEffect";

const BrandSeoSetting = ({ seo, insertSeoSettings, pageType }: any) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [seoTitleVal, setSeoTitleVal] = useState<any>(seo?.default_title || "");

  const [seoKeyWords, setSeoKeyWords] = useState<any>(
    seo?.default_keywords || ""
  );

  const [seoDesc, setSeoDesc] = useState<any>(seo?.default_keywords || "");

  const onSubmit = (inpData: any) => {
    insertSeoSettings(inpData);
  };

  return (
    <>
      <div className="bg-secondary dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
        <h1 className="text-xl">SEO Settings</h1>
        <p className="text-sm">
          Available variables are {`{{ depart_destination }}`},
          {`{{ arrival_destination }}`}, {`{{ airline }}`},
          {`{{ starting_price }}`}
        </p>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
            <TextField
              error={errors["title"] ? true : false}
              {...register("title", { required: true })}
              label="Title"
              type="text"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(
                          `Write an title for ${pageType} travelling website that help in SEO, 4-8 words: unique and without quotes`
                        );
                        setValue('title', res)
                        await typingEffect(setSeoTitleVal, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={seoTitleVal}
              onChange={(e: any) => setSeoTitleVal(e.target.value)}
            />
            <TextField
              error={errors["keywords"] ? true : false}
              {...register("keywords", { required: true })}
              label="keywords"
              type="text"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(
                          `Write an keywords for ${pageType} travelling website that help in SEO, 4-6 keywords: unique and without quotes, should be ',' separeted`
                        );
                        setValue('keywords', res)
                        await typingEffect(setSeoKeyWords, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={seoKeyWords}
              onChange={(e: any) => setSeoKeyWords(e.target.value)}
            />
            <TextField
              error={errors["canonicalUrl"] ? true : false}
              {...register("canonicalUrl", { required: true })}
              label="Canonical Url"
              type="url"
              defaultValue={seo?.canonical_url}
            />
            <TextField
              error={errors["openGraphImgUrl"] ? true : false}
              {...register("openGraphImgUrl", { required: true })}
              label="Open Graph Image Url"
              type="url"
              defaultValue={seo?.og_image_url}
            />
            <div className="md:col-span-2">
              <FormLabel>Description</FormLabel>
              <div className="relative">
                <textarea
                  {...register("description", { required: true })}
                  className={`${errors["description"]
                      ? "border-red-600 bg-transparent rounded w-full focus:border-red-600 focus:ring-red-600 h-10 p-2"
                      : "bg-transparent rounded w-full border border-secondary dark:border-dark-secondary focus:border-basic focus:ring-basic h-24 p-2"
                    }`}
                  value={seoDesc}
                  onChange={(e: any) => setSeoKeyWords(e.target.value)}
                />
                <AutoFixHighIcon
                  color="primary"
                  sx={{
                    cursor: "pointer",
                    position: "absolute",
                    right: "10px",
                    top: "5px",
                  }}
                  onClick={async () => {
                    const res = await getAiData(
                      `Write an short description for ${pageType} travelling website that help in SEO, 1 to 3 lines: unique and without quotes`
                    );
                    setValue('description', res)
                    await typingEffect(setSeoDesc, res);
                  }}
                />
              </div>
            </div>
          </div>
          <Button type="submit" sx={{ marginTop: "15px" }} variant="contained">
            Save SEO Settings
          </Button>
        </Box>
      </div>
    </>
  );
};

export default BrandSeoSetting;
