import { useMutation, useApolloClient } from "@apollo/client";
import {
  Box,
  TextField,
  Divider,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { ADD_EMAIL_TEMPLATE } from "~/graphql/def_email_templates/Mutation";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";

const AddTemplates = ({ setOpenTempMod }: any) => {
  const [role, setRole] = useState(null);
  const [dynamicFrom, setDynamicFrom] = useState(null);
  const [activity, setActivity] = useState(null);

  const client = useApolloClient();

  const [addTemplate] = useMutation(ADD_EMAIL_TEMPLATE);

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleRoleChange = (event: any) => {
    setRole(event.target.value);
  };

  const handleDynamicFromChange = (event: any) => {
    setDynamicFrom(event.target.value);
  };

  const handleActivityChange = (event: any) => {
    const value = event.target.value;
    setActivity(value === "" ? null : value);
  };

  const onSubmit = async (formData: any) => {
    const payload = {
      name: formData?.templateName,
      for_role: role,
      variables_allowed: formData?.variables,
      subject: formData?.subject,
      content: formData?.content,
      whatsapp_template_name: formData?.whatsappTempName,
      notification_content: formData?.notificationContent,
      dynamic_from: dynamicFrom,
      dynamic_from_label: formData?.dynamicFromLabel,
      is_activity: activity,
    };

    const resInsertTemp = await addTemplate({
      variables: {
        data: [payload],
      },
    });

    if (resInsertTemp?.data?.insert_def_emails_templates?.affected_rows) {
      toast.success("Template Added Successfully");
      setOpenTempMod(false);
      client.refetchQueries({
        include: "all",
      });
    }
  };

  return (
    <div>
      <h1 className="text-center text-3xl text-basic">Add Email Template</h1>
      <Divider />
      <Box onSubmit={handleSubmit(onSubmit)} component="form" noValidate>
        <div className="flex flex-col gap-5 mt-10">
          <TextField
            fullWidth
            error={errors["templateName"] ? true : false}
            {...register("templateName", { required: true })}
            label="Name"
          />
          <div className="flex gap-5">
            <FormControl fullWidth>
              <InputLabel>Role</InputLabel>
              <Select label="Role" value={role} onChange={handleRoleChange}>
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="user">User</MenuItem>
                <MenuItem value="consultant">Consultant</MenuItem>
                <MenuItem value="accountant">Accountant</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              error={errors["variables"] ? true : false}
              {...register("variables", { required: true })}
              label="Variables"
            />
          </div>
          <div className="flex gap-5">
            <TextField
              fullWidth
              error={errors["subject"] ? true : false}
              {...register("subject", { required: true })}
              label="Subject"
            />
            <TextField
              fullWidth
              error={errors["content"] ? true : false}
              {...register("content", { required: true })}
              label="Content"
            />
          </div>
          <div className="flex gap-5">
            <TextField
              fullWidth
              error={errors["whatsappTempName"] ? true : false}
              {...register("whatsappTempName", { required: true })}
              label="WhatsApp Template Name"
            />
            <TextField
              fullWidth
              error={errors["notificationContent"] ? true : false}
              {...register("notificationContent", { required: true })}
              label="Notification Content"
            />
          </div>
          <div className="flex gap-5">
            <FormControl fullWidth>
              <InputLabel>Dynamic From</InputLabel>
              <Select
                label="Dynamic From"
                value={dynamicFrom}
                onChange={handleDynamicFromChange}
              >
                <MenuItem value="true">true</MenuItem>
                <MenuItem value="false">false</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              error={errors["dynamicFromLabel"] ? true : false}
              {...register("dynamicFromLabel", { required: true })}
              label="Dynamic From Label"
            />
            <FormControl fullWidth>
              <InputLabel>Activity</InputLabel>
              <Select
                label="Activity"
                value={activity}
                onChange={handleActivityChange}
              >
                <MenuItem value="true">true</MenuItem>
                <MenuItem value="false">false</MenuItem>
                <MenuItem value="">null</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="flex items-center justify-center mt-2">
            <Button type="submit" variant="contained">
              Add Template
            </Button>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default AddTemplates;
