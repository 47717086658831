// Import react router v6
import { Routes, Route } from "react-router-dom";
// Import protected routes
import { ProtectedRoute } from "./ProtectedRoute";
// Import pages
import Dashboard from "../pages/super-admin/Dashboard";
import ProfilePage from "~/pages/super-admin/ProfilePage";
import TravelHouses from "~/pages/super-admin/TravelHouses";
import BrandDetails from "~/pages/super-admin/BrandDetails";
import Seasonal from "~/pages/super-admin/Seasonal";
import EmailTemplates from "~/pages/super-admin/EmailTemplates";
import DefPlanCreditsPage from "~/pages/super-admin/DefPlanCreditsPage";

export default function SuperAdminRoutes() {
  return (
    <Routes>
      <Route
        path="/super-admin"
        element={<ProtectedRoute role="super-admin" />}
      >
        <Route index element={<Dashboard />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="travel-houses" element={<TravelHouses />} />
        <Route path="/super-admin/brands-details" element={<BrandDetails />} />
        <Route path="/super-admin/seasonal" element={<Seasonal />} />
        <Route
          path="/super-admin/email-templates"
          element={<EmailTemplates />}
        />
        <Route
          path="/super-admin/credits-purchases"
          element={<DefPlanCreditsPage />}
        />
      </Route>
    </Routes>
  );
}
