export const CabinClasses = [
  {
    id: 1,
    title: "Economy",
    value: "economy",
  },
  {
    id: 2,
    title: "Business",
    value: "business",
  },
];
